import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input,
    Button
} from 'reactstrap';


import moment from 'moment';

import { Player, Controls } from "@lottiefiles/react-lottie-player";

import no_content from '../assets/images/no_content.png';

import arrow_left from '../assets/arrow-left.svg';
import list_1 from '../assets/list-1.svg';
import list_2 from '../assets/list-2.svg';
import arrow_right from '../assets/arrow-right.svg';
import weather from '../assets/weather.svg';
import evidency from '../assets/evidency.png';
import sugestije from '../assets/sugestije.png';
import app from '../assets/app.png';
import b1 from '../assets/b1.png';
import shape1 from '../assets/svg/shape-1.svg';
import shape2 from '../assets/svg/shape-2.svg';
import noImage from '../assets/no-image.png';


import details_naslovna from '../assets/details-naslovna.jpg';

import ReactPaginate from 'react-paginate';
import RightSidebar from '../components/rightSidebar';
import { API_ENDPOINT } from '../constants';

import { Redirect } from 'react-router-dom';


var striptags = require('striptags');

class BlogPage extends Component {
    constructor(props) {
        super(props);
        this.updateStateFromSearch = this.updateStateFromSearch.bind(this);
        this.updateParam = this.updateParam.bind(this);
        this.get = this.get.bind(this);

        this.state = {
            promotedProjects: [],
            page: 0,
            view: 0,
            showCommentBox: false,
            ...props.initialData
        };
    }

    updateStateFromSearch(callback) {
        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = { page: 0 };
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        this.setState({
            category: null,
            tag: null,
            items: []
        }, () => {
            this.setState(params, callback);

        })

    }


    updateParam(name, value) {

        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        params[name] = value;

        let paramsArr = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {

                paramsArr.push(key + "=" + params[key]);
            }
        }



        let search = '?' + paramsArr.join('&');


        this.props[0].history.push(this.props[0].location.pathname + search);
        ////console.log(search);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.updateStateFromSearch(() => {
                this.get()
            });
        }
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.get()
        }
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }



        // this.updateStateFromSearch(this.get);
        this.get()

    }
    get() {

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }


        fetch(`https://interni-api.euroexpress.ba/log/new`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                path: this.props[0].location.pathname,
            })
        }).then(res => res.json()).then((result) => {

        })





    }

    sendComment = (id, comment) => {
        if (this.props.user) {
            let obj = {
                poolId: id,
                comment: comment,
                userId: this.props.user._id
            }

            fetch('https://interni-api.euroexpress.ba/pool/comment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${typeof window != 'undefined' ? localStorage.getItem('token') : null}`

                },
                body: JSON.stringify(obj)
            }).then((res) => res.json()).then((result) => {
                this.setState({
                    comment: null,
                }, () => this.get())
            })
        }
    }


    render() {
        return (
            <div>
                {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null
                }
                <img src={shape1} className="shape-1" />
                <img src={shape2} className="shape-2" />
                <section id="content">
                    <Container>
                        <Row>
                            <Col lg="9" className="nop">
                                <div className="content l-0 r-fix pd0">
                                    <div className="survey-page">
                                        <h5 className="section-title">Anketa</h5>
                                        <p>{this.state.pool && this.state.pool.name}</p>
                                        <form>
                                            {
                                                this.state.answers && this.state.answers.map((item, idx) => {
                                                    if (item != "")
                                                        return (
                                                            <div className="form-group survey-center" key={idx}>
                                                                <div className="bar">
                                                                    <div className={item.maxElement ? "bar-blue" : "bar-red"} style={{ width: item.votesPercent + '%' }}></div>

                                                                </div>


                                                                <span>{item.answer}</span>
                                                            </div>
                                                        )
                                                })
                                            }

                                        </form>
                                        <div className="form-group survey-center bgct cc">
                                            <div className="votes"></div>

                                            <div className="votes">
                                                {this.state.pool && this.state.pool.votesNumber}
                                                {
                                                    this.state.pool && this.state.pool.votesNumber ?
                                                        Number(String(this.state.pool.votesNumber).charAt(String(this.state.pool.votesNumber).length - 1)) == 1 && Number(String(this.state.pool.votesNumber).charAt(String(this.state.pool.votesNumber).length - 2)) != 1 ?
                                                            <> glas</>
                                                            :
                                                            (Number(String(this.state.pool.votesNumber).charAt(String(this.state.pool.votesNumber).length - 1)) == 2 || Number(String(this.state.pool.votesNumber).charAt(String(this.state.pool.votesNumber).length - 1)) == 3 || Number(String(this.state.pool.votesNumber).charAt(String(this.state.pool.votesNumber).length - 1)) == 4) && Number(String(this.state.pool.votesNumber).charAt(String(this.state.pool.votesNumber).length - 2)) != 1 ?
                                                                <> glasa</>
                                                                :
                                                                <> glasova</>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    {
                                        this.state.showCommentBox && this.state.pool && this.state.pool._id ?
                                            <div className="survey-comment-box-wrap">
                                                <Input type='textarea' placeholder='Ostavite komentar...' value={this.state.comment} onChange={(e) => this.setState({ comment: e.target.value })} />
                                                <button
                                                    disabled={this.state.comment && this.state.comment.trim() ? false : true}
                                                    className={this.state.comment && this.state.comment.trim() ? '' : 'opacity-06'}
                                                    onClick={() => {
                                                        this.sendComment(this.state.pool._id, this.state.comment)

                                                    }}>Pošalji komentar</button>
                                            </div>
                                            :
                                            false
                                    }

                                    <div className="survey-comments">
                                        {
                                            this.state.comments && this.state.comments.map((item, idx) => {
                                                return (
                                                    <div className="comment">
                                                        <div className="comment-user">
                                                            {
                                                                item && item.userProfile && item.userProfile.image ?
                                                                    <img src={API_ENDPOINT + item.userProfile.image} />
                                                                    :
                                                                    <img src={noImage} />
                                                            }
                                                            {
                                                                item && item.userProfile && item.userProfile.name ?
                                                                    <h6>{item.userProfile.name}</h6>
                                                                    :
                                                                    null
                                                            }
                                                        </div>

                                                        <div className="comment-text">
                                                            <p>{item.comment}</p>

                                                            <div className="comment-ts">
                                                                <h5>{moment.unix(item.timestamp).format('DD.MM.YYYY. HH:mm')}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col lg="3" className="nop">
                                {
                                    this.state.home && this.state.home.statistics ?
                                        <RightSidebar
                                            totalVehicles={this.state.home.statistics.totalVehicles}
                                            newMembers={this.state.home.statistics.newMembers}
                                            deliveryLines={this.state.home.statistics.deliveryLines}
                                            warehouses={this.state.home.statistics.warehouses}
                                            shops={this.state.home.statistics.shops}
                                            googlePlayLink={this.state.home.googlePlayLink}
                                            appStoreLink={this.state.home.appStoreLink}
                                            totalEmployess={this.state.home.statistics.totalEmployess}
                                        />
                                        :
                                        null
                                }
                            </Col>
                        </Row>
                    </Container>

                </section>

            </div>
        );
    }
}

export default Page(BlogPage);