import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,

} from 'reactstrap';



import facebook from '../assets/svg/footer-facebook.svg';
import instagram from '../assets/svg/footer-instagram.svg';
import linkedin from '../assets/svg/footer-linkedin.svg';
import youtube from '../assets/svg/youtube.svg';


export class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    render() {
        return (
            <div>
                <Container>
                    <Row>
                        <Col lg="12">
                            <footer>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="copyright">
                                                <div className="copyright-content">
                                                    <p>Copyright © EuroExpress d.o.o. - 2020. Sva prava zadržana.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="social">
                                                <div className="social-content">
                                                    <span>Naše društvene mreže</span>
                                                    <ul className="social-list">
                                                        <li>
                                                            <a href="https://www.facebook.com/euroexpress.ba/" target="_blank">
                                                                <Isvg src={facebook} />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.instagram.com/euroexpressbrzaposta/" target="_blank">
                                                                <Isvg src={instagram} />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.linkedin.com/company/9464536/" target="_blank">
                                                                <Isvg src={linkedin}/>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.youtube.com/channel/UCWnmVbqSlWKGamJWapDa2Wg" target="_blank">
                                                                <Isvg src={youtube}/>
                                                            </a>
                                                        </li>
                                                        
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </footer>
                        </Col>
                    </Row>
                </Container>

            </div>
        )
    }
}

export default Footer;