import React, { Component } from 'react';
import Link from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import Slider from '../components/slider';
import { Redirect } from 'react-router-dom';



import {
    Container,
    Row,
    Col,
} from 'reactstrap';


import Image from '../components/image';

import downChevron from '../assets/svg/down-chevron.svg';
import Newsletter from '../components/newsletter';


import RightSidebar from '../components/rightSidebar';

import { API_ENDPOINT } from '../constants';


class ServicePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }

        fetch(`https://interni-api.euroexpress.ba/log/new`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                path: this.props[0].location.pathname,
            })
        }).then(res => res.json()).then((result) => {

        })

        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
            this.get()

            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }

        }
      
    }



    render() {
        return (
            <div>
                {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null
                }
                <section id="content">
                    <Container>
                        <Row>
                            <Col lg="9" className="nop">
                                <div className="content l-0 r-fix">
                                    <div className="content-container">
                                        <Row className="delivery-image">
                                            <Col lg="12">
                                                <h1>Dostavne linije</h1>
                                            </Col>
                                            {
                                                !this.state.delivery || !this.state.delivery.length ?
                                                    <div className="no-content"><p>Stranica u izradi...</p></div>
                                                    :
                                                    null
                                            }
                                            {
                                                this.state.delivery && this.state.delivery.map((item, idx) => {
                                                    return (
                                                        <Col lg="12">
                                                            <img src={API_ENDPOINT + item} />
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </div>


                                </div>
                            </Col>
                            <Col lg="3" className="nop">
                                {
                                    this.state.home && this.state.home.statistics ?
                                        <RightSidebar
                                            totalVehicles={this.state.home.statistics.totalVehicles}
                                            newMembers={this.state.home.statistics.newMembers}
                                            deliveryLines={this.state.home.statistics.deliveryLines}
                                            warehouses={this.state.home.statistics.warehouses}
                                            shops={this.state.home.statistics.shops}
                                            googlePlayLink={this.state.home.googlePlayLink}
                                            appStoreLink={this.state.home.appStoreLink}
                                            totalEmployess={this.state.home.statistics.totalEmployess}
                                        />
                                        :
                                        null
                                }
                            </Col>
                        </Row>
                    </Container>

                </section>

            </div>
        );
    }
}

export default Page(ServicePage);