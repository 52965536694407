import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Container, Row, Col
} from 'reactstrap';
import moment from 'moment';


class PoolModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        console.log(this.props)
        return (

            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} >

                <ModalBody className="delete-modal">
                    {this.props.item.name}
                    
                </ModalBody>
                <ModalFooter>
                    <Row>
                    {
                        this.props.item && this.props.item.answers && this.props.item.answers.map((item, idx) => {
                            return(
                                <>
                                <Col lg="2">
                                    {idx+1}
                                </Col>

                                <Col lg="6">
                                    {item.answer}
                                </Col>
                                <Col lg="4">
                                    <Button className="vote-button" onClick={() => {
                                         this.props.handler(idx);
                                         this.props.toggle()
                                    }}>Glasaj</Button>
                                </Col>
                                </>
                            )
                        })
                    }
                    </Row>
                </ModalFooter>
            </Modal>
        )
    }
}

export default PoolModal;