

import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,

} from 'reactstrap';



import facebook from '../assets/facebook.svg';
import instagram from '../assets/instagram.svg';
import linkedin from '../assets/linkedin.svg';

import logo from '../assets/logo.png';
import zajednica from '../assets/zajednica.png';
import arrow_black from '../assets/arrow-black.svg';
import arrow_white from '../assets/arrow-white.svg';
import news_icon from '../assets/news-icon.svg';
import resources_icon from '../assets/resources-icon.svg';
import commercial_icon from '../assets/commercial-icon.svg';
import home_icon from '../assets/home-icon.svg';
import logistic_icon from '../assets/logistic-icon.svg';
import shop_icon from '../assets/shop-icon.svg';
import more_icon from '../assets/svg/more.svg'
import close_icon from '../assets/svg/close-ico.svg'

import profile from '../assets/profile.png';
import bars from '../assets/bars.png';

import { API_ENDPOINT } from '../constants';


export class Nav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mobileMenu: false
        };
    }

    componentDidMount() {
        fetch('https://interni-api.euroexpress.ba/menu', {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        }).then(res => res.json()).then((result) => {
            console.log(result);
            this.setState({
                menu: result
            })
        })

        fetch('https://interni-api.euroexpress.ba/submenu', {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        }).then(res => res.json()).then((result) => {
            console.log(result);
            this.setState({
                submenu: result
            })
        })


    }
    logout = () => {

        localStorage.removeItem('token');
        window.location.reload(false);

    }


    render() {
        return (
            <div>
                <Container>
                    <Row>
                        <Col lg="12" className="col-no-padding">
                            <nav>
                                <Container>
                                    <Row>
                                        <Col lg="12">
                                            <img src={bars} className="menu-icon" alt="menu-icon" />
                                            <ul className="navigation">
                                                <li>
                                                    <Link to="/" className="menu-link">
                                                        <span className="link-icon">
                                                            <img src={home_icon} alt="home-icon" />
                                                        </span>
                                                        <span className="link-text"> Početna</span>
                                                    </Link>
                                                </li>
                                                {
                                                    this.state.menu && this.state.menu.map((item, idx) => {
                                                        return (
                                                            <li>
                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle className="menu-link">
                                                                        <span className="link-icon">
                                                                            <img src={API_ENDPOINT + item.icon} alt="news-icon" />
                                                                        </span>
                                                                        <span className="link-text">{item.name}</span>
                                                                        <span className="dropdown-icon">
                                                                            <img src={arrow_white} alt="" />
                                                                        </span>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        {
                                                                            this.state.submenu && this.state.submenu.map((submenu, i) => {
                                                                                if (submenu.menu == item._id)
                                                                                    return (
                                                                                        <Link to={`/${item.link}/novosti/${submenu.link}`}><DropdownItem>  {submenu.name} </DropdownItem></Link>
                                                                                    )
                                                                            })
                                                                        }
                                                                        {
                                                                            item.link == "interne-novosti" ?
                                                                                <>
                                                                                    <Link to={`/${item.link}/dokumenti`}> <DropdownItem> Materijali za poslovnu komunikaciju  </DropdownItem></Link>
                                                                                </>
                                                                                :
                                                                                null
                                                                        }


                                                                        {
                                                                            item.link == "ljudski-resursi" ?
                                                                                <>
                                                                                    <Link to={`/predstavljanje-novozaposlenih`}><DropdownItem> Predstavljanje zaposlenih  </DropdownItem></Link>
                                                                                    <Link to={`/kalendar`}> <DropdownItem>  Kalendar odsutnih </DropdownItem> </Link>
                                                                                    <Link to={`/${item.link}/dokumenti`}> <DropdownItem>  Formulari i obrasci  </DropdownItem></Link>

                                                                                    <Link to={`/kontakt`}>  <DropdownItem> Kutak za ideje </DropdownItem></Link>
                                                                                </>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            item.link == "komercijala" ?
                                                                                <>
                                                                                    <Link to={`/${item.link}/dokumenti`}>  <DropdownItem>Komercijalne ponude - obrasci</DropdownItem> </Link>
                                                                                </>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            item.link == "logistika" ?
                                                                                <>
                                                                                    <Link to={`/dostavne-linije`}> <DropdownItem>  Naše linije  </DropdownItem></Link>
                                                                                </>
                                                                                :
                                                                                null
                                                                        }

                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>

                                                            </li>

                                                        )
                                                    })}
                                                <li>
                                                    <Link to="/interni-shop" className="menu-link">
                                                        <span className="link-icon">
                                                            <img src={shop_icon} alt="home-icon" />
                                                        </span>
                                                        <span className="link-text"> Interni shop</span>
                                                    </Link>
                                                </li>

                                            </ul>
                                            <div className="mobile-profile">
                                                <div className="profile-name">
                                                    <h6>John Doe <span> <img src={arrow_black} alt="arrow" /> </span> </h6>
                                                </div>
                                                <div className="profile-image">
                                                    <img src={profile} alt="profile" />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>

                            </nav>
                            <div className="nav-mobile">
                                <Link to="/">
                                    <div className="nav-mobile-logo">
                                        <img src={logo} />
                                    </div>
                                </Link>
                                <div className="nav-mobile-bars" onClick={() => this.setState({ mobileMenu: !this.state.mobileMenu })}>
                                    <Isvg src={more_icon} />
                                </div>


                            </div>
                            <div className={this.state.mobileMenu ? 'mobile-menu mobile-right-menu-open' : 'mobile-menu'}>
                                <div className="content" ref={(node) => this.rightMenuRef = node}>

                                    <button className="close_menu" onClick={() => { this.setState({ mobileMenu: !this.state.mobileMenu }) }}><Isvg src={close_icon} /></button>
                                    <div className="navigation-wrap">
                                        <ul className="navigation">
                                            <li>
                                                <Link to="/" className="menu-link">
                                                    <span className="link-icon">
                                                        <img src={home_icon} alt="home-icon" />
                                                    </span>
                                                    <span className="link-text"> Početna</span>
                                                </Link>
                                            </li>
                                            {
                                                this.state.menu && this.state.menu.map((item, idx) => {
                                                    return (
                                                        <li>
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle className="menu-link">
                                                                    <span className="link-icon">
                                                                        <img src={API_ENDPOINT + item.icon} alt="news-icon" />
                                                                    </span>
                                                                    <span className="link-text">{item.name}</span>
                                                                    <span className="dropdown-icon">
                                                                        <img src={arrow_white} alt="" />
                                                                    </span>
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    {
                                                                        this.state.submenu && this.state.submenu.map((submenu, i) => {
                                                                            if (submenu.menu == item._id)
                                                                                return (
                                                                                    <DropdownItem><Link to={`/${item.link}/novosti/${submenu.link}`}>  {submenu.name} </Link></DropdownItem>
                                                                                )
                                                                        })
                                                                    }


                                                                    {
                                                                        item.link == "ljudski-resursi" ?
                                                                            <>
                                                                                <DropdownItem><Link to={`/predstavljanje-novozaposlenih`}> Predstavljanje zaposlenih </Link></DropdownItem>
                                                                                <DropdownItem><Link to={`/kalendar`}>  Kalendar odsutnih </Link></DropdownItem>
                                                                                <DropdownItem><Link to={`/${item.link}/dokumenti`}>  Formulari i obrasci </Link></DropdownItem>

                                                                                <DropdownItem><Link to={`/kontakt`}>  Kutak za ideje </Link></DropdownItem>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        item.link == "komercijala" ?
                                                                            <>
                                                                                <DropdownItem><Link to={`/${item.link}/dokumenti`}> Komercijalne ponude - obrasci </Link></DropdownItem>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        item.link == "logistika" ?
                                                                            <>
                                                                                <DropdownItem><Link to={`/dostavne-linije`}>  Naše linije </Link></DropdownItem>
                                                                            </>
                                                                            :
                                                                            null
                                                                    }

                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>

                                                        </li>

                                                    )
                                                })}
                                            <li>
                                                <Link to="/interni-shop" className="menu-link">
                                                    <span className="link-icon">
                                                        <img src={shop_icon} alt="home-icon" />
                                                    </span>
                                                    <span className="link-text"> Interni shop</span>
                                                </Link>
                                            </li>
                                            {
                                                this.props.user ?
                                                    <div className="login-logout">
                                                        <button onClick={() => this.logout()}>Odjavi se</button>
                                                    </div>
                                                    :
                                                    <div className="login-logout">
                                                        <Link to="/prijava"><button>Prijavi se</button></Link>
                                                    </div>
                                            }


                                        </ul>


                                    </div>



                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Nav;
