import React, { Component } from 'react';
import Link from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import Slider from '../components/slider';
import { Redirect } from 'react-router-dom';



import {
    Container,
    Row,
    Col,
} from 'reactstrap';


import weather from '../assets/weather.svg';
import evidency from '../assets/evidency.png';
import sugestije from '../assets/sugestije.png';
import app from '../assets/app.png';
import b1 from '../assets/b1.png';

import details_naslovna from '../assets/details-naslovna.jpg';


import help_icon from '../assets/help-icon.svg';
import warning_icon from '../assets/warning-icon.svg';
import mail_icon from '../assets/mail-icon.svg';
import chart_icon from '../assets/chart-icon.svg';
import star from '../assets/star.svg';
import like from '../assets/like.svg';
import shape1 from '../assets/svg/shape-1.svg';
import shape2 from '../assets/svg/shape-2.svg';

import book_1 from '../assets/book-1.png';
import team_member from '../assets/team-member.png';
import moment from 'moment';

import RightSidebar from '../components/rightSidebar';
import { API_ENDPOINT } from '../constants';

import Form from '../components/forms/changePasswordForm';
import Form2 from '../components/forms/changeProfileForm';


class BlogDetailPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }

        fetch(API_ENDPOINT + `/log/new`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                path: this.props[0].location.pathname,
            })
        }).then(res => res.json()).then((result) => {

        })
        this.get();

        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

    }
    get = () => {
        fetch('https://interni-api.euroexpress.ba/user', {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => res.json()).then((result) => {
            console.log(result);
            this.setState({
                user: result
            })
        })
    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }
            this.get();
        }


    }
    submit = (data) => {
        if (data.newPassword == data.retypedPassword) {
            fetch(API_ENDPOINT + `/change/password`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.props[0].history.push("/")
            })
        }
    }
    submitPhoto = (data) => {

        if (data && data.image) {
            fetch(API_ENDPOINT + `/change/profile/picture`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ image: data.image })
            }).then(res => res.json()).then((result) => {
                this.props.verifyUser()
                this.props[0].history.push("/")
            })
        }


    }


    render() {
        return (
            <div>
                {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null
                }
                <img src={shape1} className="shape-1" />
                <img src={shape2} className="shape-2" />
                <section id="content">
                    <Container>
                        <Row>
                            <Col lg="9" className="nop">
                                <div className="content l-0 r-fix pd0">
                                    <div className="content-container sugestion-container">

                                        <Form onSubmit={this.submit} />
                                        <br/><hr/><br/>
                                        {
                                            this.state.user ?
                                                <Form2 initialValues={this.state.user} onSubmit={this.submitPhoto} />
                                                :
                                                <Form2 onSubmit={this.submitPhoto} />
                                        }


                                    </div>


                                </div>
                            </Col>
                            <Col lg="3" className="nop">
                                {
                                    this.state.home && this.state.home.statistics ?
                                        <RightSidebar
                                            totalVehicles={this.state.home.statistics.totalVehicles}
                                            newMembers={this.state.home.statistics.newMembers}
                                            deliveryLines={this.state.home.statistics.deliveryLines}
                                            totalEmployess={this.state.home.statistics.totalEmployess}
                                            warehouses={this.state.home.statistics.warehouses}
                                            shops={this.state.home.statistics.shops}
                                            googlePlayLink={this.state.home.googlePlayLink}
                                            appStoreLink={this.state.home.appStoreLink}
                                        />
                                        :
                                        null
                                }
                            </Col>
                        </Row>
                    </Container>

                </section>

            </div>
        );
    }
}

export default Page(BlogDetailPage);