import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';


import moment from 'moment';

import arrow_left from '../assets/arrow-left.svg';
import list_1 from '../assets/list-1.svg';
import list_2 from '../assets/list-2.svg';
import arrow_right from '../assets/arrow-right.svg';
import weather from '../assets/weather.svg';
import evidency from '../assets/evidency.png';
import sugestije from '../assets/sugestije.png';
import app from '../assets/app.png';
import b1 from '../assets/b1.png';
import shape1 from '../assets/svg/shape-1.svg';
import shape2 from '../assets/svg/shape-2.svg';

import { Player, Controls } from "@lottiefiles/react-lottie-player";

import no_content from '../assets/images/no_content.png';

import details_naslovna from '../assets/details-naslovna.jpg';

import ReactPaginate from 'react-paginate';
import RightSidebar from '../components/rightSidebar';
import { API_ENDPOINT } from '../constants';

import { Redirect } from 'react-router-dom';


var striptags = require('striptags');

class BlogPage extends Component {
    constructor(props) {
        super(props);
        this.updateStateFromSearch = this.updateStateFromSearch.bind(this);
        this.updateParam = this.updateParam.bind(this);
        this.get = this.get.bind(this);

        this.state = {
            promotedProjects: [],
            page: 0,
            view: 0,
            ...props.initialData
        };
    }

    updateStateFromSearch(callback) {
        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = { page: 0 };
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        this.setState({
            category: null,
            tag: null,
            items: []
        }, () => {
            this.setState(params, callback);

        })

    }


    updateParam(name, value) {

        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        params[name] = value;

        let paramsArr = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {

                paramsArr.push(key + "=" + params[key]);
            }
        }



        let search = '?' + paramsArr.join('&');


        this.props[0].history.push(this.props[0].location.pathname + search);
        ////console.log(search);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.updateStateFromSearch(() => {
                this.get()
            });
        }
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.get()
        }
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }

        fetch(`https://interni-api.euroexpress.ba/log/new`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                path: this.props[0].location.pathname,
            })
        }).then(res => res.json()).then((result) => {

        })


        this.updateStateFromSearch(this.get);

        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));


    }
    get() {

        fetch(`https://interni-api.euroexpress.ba/library`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: this.state.page,
                lang: this.props.lang
            })
        }).then(res => res.json()).then((result) => {

            this.setState({
                items: result.items,
                total: result.total,
            })
        })

        
    }


    render() {
        return (
            <div>
                {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null
                }
                <img src={shape1} className="shape-1" />
                <img src={shape2} className="shape-2" />
                <section id="content">
                    <Container>
                        <Row>
                            <Col lg="9" className="nop">
                                <div className="content l-0 r-fix pd0">
                                    <div className="content-container">
                                        <div className="news-list-title">
                                            <div className="title-content">
                                                <h5 className="section-title">Interna biblioteka</h5>
                                                <div className="breadcumb">
                                                    <span>
                                                        <Link to="/">Početna</Link>&nbsp;/&nbsp;<a>Interne novosti</a>&nbsp;/&nbsp;<a className="b-active">Interna biblioteka</a>
                                                    </span>


                                                </div>
                                            </div>

                                        </div>

                                        <div className="content-1">

                                            <Row className="nop">
                                                {
                                                    !this.state.items || !this.state.items.length ?
                                                        // <div className="no-content">
                                                        //     <img src={no_content} />
                                                        //     <p>Stranica u izradi...</p>

                                                        // </div>
                                                        <div className="no-content" >
                                                            <Player
                                                                autoplay={true}
                                                                loop={true}
                                                                src="/lf30_editor_w4hitwma.json"
                                                                style={{ width: "60%" }}
                                                            ></Player>
                                                            <p>Stranica u izradi...</p>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                {
                                                    this.state.items && this.state.items.map((item, idx) => {
                                                        return (
                                                            // <Col lg="12" className="article-grid-list">
                                                            //     <a>
                                                            //         <article className="article">
                                                            //             <div className="article-header bg-1">
                                                            //                 <div className="image-wrap">
                                                            //                     <img src={API_ENDPOINT + item.image} />
                                                            //                 </div>
                                                            //             </div>
                                                            //             <div className="article-body">
                                                            //                 <span className="mob-none">Naslov</span>
                                                            //                 {typeof window != 'undefined' && window.innerWidth < 1024 ?
                                                            //                     item.name.length > 35 ?
                                                            //                         <h3>{item.name.substring(0, 35)}...</h3>
                                                            //                         :
                                                            //                         <h3>{item.name}</h3>
                                                            //                     :
                                                            //                     item.name.length > 50 ?
                                                            //                         <h3>{item.name.substring(0, 50)}...</h3>
                                                            //                         :
                                                            //                         <h3>{item.name}</h3>
                                                            //                 }
                                                            //                 <span className="mob-none">Opis</span>
                                                            //                 {typeof window != 'undefined' && window.innerWidth < 1024 ?
                                                            //                     item.shortDescription.length > 40 ?
                                                            //                         <p>{item.shortDescription.substring(0, 40)}...</p>
                                                            //                         :
                                                            //                         <p>{item.shortDescription}</p>
                                                            //                     :
                                                            //                     item.shortDescription.length > 110 ?
                                                            //                         <p>{item.shortDescription.substring(0, 110)}...</p>
                                                            //                         :
                                                            //                         <p>{item.shortDescription}</p>}
                                                            //                 <a href={item.link} target="_blank">Pročitaj detaljnije</a>
                                                            //             </div>
                                                            //         </article>
                                                            //     </a>
                                                            // </Col>
                                                            <Col lg='6'>
                                                                <div className="libary libary-list">
                                                                    <div className="libary-content">
                                                                        <img src={API_ENDPOINT + item.image} alt="" />
                                                                        <div className="libary-text">
                                                                            <h6>{item.name}</h6>
                                                                            <p>{item.shortDescription}</p>
                                                                            <a href={API_ENDPOINT + item.link} target="_blank">Pročitaj detaljnije</a>

                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            </Row>
                                            {
                                                this.state.total / 5 > 1 ?
                                                    <Row>
                                                        <Col lg="12">
                                                            <ReactPaginate
                                                                previousLabel={''.translate(this.props.lang)}
                                                                nextLabel={''.translate(this.props.lang)}
                                                                breakLabel={'...'}
                                                                breakClassName={'break-me'}
                                                                pageCount={this.state.total / 5}
                                                                marginPagesDisplayed={1}
                                                                pageRangeDisplayed={2}
                                                                onPageChange={(page) => { this.updateParam('page', page.selected); window.scrollTo(0, 0); }}
                                                                // onPageChange={(page) => { this.props[0].history.push(this.generateSearchLink('page', page.selected)) }}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                                hrefBuilder={(page) => { return `?page=${page}` }}
                                                            // hrefBuilder={(page) => { return this.generateSearchLink('page', page) }}
                                                            //forcePage={this.state.page}
                                                            />

                                                        </Col>

                                                    </Row>

                                                    :

                                                    null
                                            }


                                        </div>



                                    </div>
                                </div>

                            </Col>
                            <Col lg="3" className="nop">
                                {
                                    this.state.home && this.state.home.statistics ?
                                        <RightSidebar
                                            totalVehicles={this.state.home.statistics.totalVehicles}
                                            newMembers={this.state.home.statistics.newMembers}
                                            deliveryLines={this.state.home.statistics.deliveryLines}
                                            warehouses={this.state.home.statistics.warehouses}
                                            shops={this.state.home.statistics.shops}
                                            googlePlayLink={this.state.home.googlePlayLink}
                                            appStoreLink={this.state.home.appStoreLink}
                                            totalEmployess={this.state.home.statistics.totalEmployess}
                                        />
                                        :
                                        null
                                }
                            </Col>
                        </Row>
                    </Container>

                </section>

            </div>
        );
    }
}

export default Page(BlogPage);