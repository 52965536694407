import HomePage from './views/homePage';
import CalendarPage from './views/calendarPage';
import ContactPage from './views/contactPage';
import DocumentPage from './views/documentPage';
import BlogPage from './views/blogPage';
import BlogDetailPage from './views/blogDetailPage';
import Service1Page from './views/service1Page';
import LoginPage from './views/loginPage';
import PrecautionsPage from './views/precautionsPage';
import Employees from './views/employees';
import Library from './views/libraryPage';

import ForgetPassword from './views/forgetPassword';
import PoolPage from './views/poolPage';
import PoolImagePage from './views/poolImagePage';


import NewMemberPreview from './views/newMemberPreview';

import ShopPage from './views/shopPage';
import ShopDetailPage from './views/shopDetailPage';



import ChangePassword from './views/changePassword';
import InterniShop from './views/interniShop';
import PoolVideoPage from './views/poolVideoPage';

const linkTranslates = {

    'en': {
        '/': '/en',
        '/dostavne-linije': '/en/delivery-lines',
        '/dokumenti': '/en/documents',
        '/kalendar': '/en/calendar',
        '/:category/novosti/:submenu': '/en/:category/blog/:submenu',
        '/:category/novosti/:submenu/:alias': '/en/:category/blog/:submenu/:alias',
        '/kontakt': '/en/contact',
        '/prijava': '/en/login',
        '/zaboravljena-lozinka': '/en/zaboravljena-lozinka',
        '/mjere-predostroznosti': '/en/precautions',
        '/arhiva-novosti': '/en/all-blogs',
        '/predstavljanje-novozaposlenih': '/en/ddd',
        '/podesavanja': '/en/settings',
        '/interni-shop': '/en/interni/shop',
        '/interni-shop/:category/:id': '/en/interni-shop/:category/:id',
        '/interne-novosti/interna-biblioteka': '/interne-novosti/interna-biblioteka',
        '/anketa': '/en/anketa',
        '/novi-clan': '/en/novi-clan',
        '/anketa-slike': '/en/anketa-slike',
        '/anketa-video': '/en/anketa-video',
    },
}

export const routes = [
    {
        path: "/",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Početna'.translate(lang) + ' - Interni portal EuroExpress brza pošta'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://interni.euroexpress.ba/share.png'
            }
        },
        component: HomePage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/pools/all', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${typeof window != 'undefined' ? localStorage.getItem('token') : null}`
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        pool: data,
                        answers: data.answers,
                        comments: data.comments
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/home', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        home: data
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/home/latest/articles', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    for (let i = 0; i < data.length; i++) {
                        data[i].dateTS = new Date(data[i].date + " " + data[i].time).getTime() / 1000;
                    }
                    return {
                        homeLatestArticles: data
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://interni-api.euroexpress.ba/employee`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((result) => {

                    return {
                        newMember: result.items
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://interni-api.euroexpress.ba/library`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((result) => {

                    return {
                        library: result.items
                    }
                })

            }





        ]

    },
    {
        path: "/prijava",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Login'.translate(lang) + ' - Interni portal EuroExpress brza pošta'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://interni.euroexpress.ba/share.png'
            }
        },
        component: LoginPage,
        loadData: []
    },
    {
        path: "/zaboravljena-lozinka",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Zaboravljena lozinka'.translate(lang) + ' - Interni portal EuroExpress brza pošta'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://interni.euroexpress.ba/share.png'
            }
        },
        component: ForgetPassword,
        loadData: []
    },

    {
        path: "/kalendar",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Kalendar odsutnih'.translate(lang) + ' - Interni portal EuroExpress brza pošta'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://interni.euroexpress.ba/share.png'
            }
        },
        component: CalendarPage,
        loadData: [
            // (fetchFunction, lang, match) => {
            //     // console.log(this.props.user)
            //     return fetchFunction(`https://interni-api.euroexpress.ba/absence`, {
            //         method: 'GET',
            //         headers: {
            //             'content-type': 'application/json'
            //         }
            //     }).then(res => res.json()).then((data) => {
            //         return {
            //             data
            //         }
            //     })

            // },
            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/home', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        home: data
                    }
                })

            }
            // (fetchFunction, lang, match) => {
            //     return fetchFunction('https://interni-api.euroexpress.ba/blog/latest', {
            //         method: 'GET',
            //         headers: {
            //             'content-type': 'application/json'
            //         },

            //     }).then(res => res.json()).then((result) => {

            //         return {
            //             latestBlog: result,
            //         }
            //     })

            // }



        ]

    },

    {
        path: "/interni-shop",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Interni shop'.translate(lang) + ' - Interni portal EuroExpress brza pošta'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://interni.euroexpress.ba/share.png'
            }
        },
        component: ShopPage,
        loadData: [

            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/home', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        home: data
                    }
                })

            }

        ],
        loadDataWithQuery: [
            // (fetchFunction, lang, match, query) => {
            //     return fetchFunction(`https://interni-api.euroexpress.ba/shop/articles/all`, {
            //         method: 'POST',
            //         headers: {
            //             'content-type': 'application/json',
            //             'Authorization': `Bearer ${typeof window != 'undefined' ? localStorage.getItem('token') : null}`
            //         },
            //         body: JSON.stringify({
            //             page: query.page,
            //             lang: lang,
            //             limit: 9
            //         })
            //     }).then(res => res.json()).then((result) => {
            //         // for (let i = 0; i < result.items.length; i++) {
            //         //     result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
            //         // }
            //         return {
            //             page: query.page ? query.page : 0,
            //             items: result.items,
            //             total: result.total,

            //         }
            //     })

            // },
            // (fetchFunction, lang, match, query) => {
            //     return fetchFunction(`https://interni-api.euroexpress.ba/shop/categories/all`, {
            //         method: 'POST',
            //         headers: {
            //             'content-type': 'application/json',
            //             'Authorization': `Bearer ${typeof window != 'undefined' ? localStorage.getItem('token') : null}`
            //         },
            //         body: JSON.stringify({
            //         })
            //     }).then(res => res.json()).then((result) => {

            //         return {
            //             shopCategories: result

            //         }
            //     })

            // }



        ]


    },
    {
        path: "/interni-shop/:category/:id",
        generateSeoTags: (data, lang) => {
            return {
                title: (data?.data && data.data.name ? data.data.name : 'Interni shop') + ' - Interni portal EuroExpress brza pošta'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://interni.euroexpress.ba/share.png'
            }
        },
        component: ShopDetailPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://interni-api.euroexpress.ba/shop/articles/${match.params.category}/${match.params.id}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${typeof window != 'undefined' ? localStorage.getItem('token') : null}`
                    },

                }).then(res => res.json()).then((result) => {

                    // result.item.dateTS = new Date(result.item.date + " " + result.item.time).getTime() / 1000;
                    // if (result && result.item && result.item.gallery) {
                    //     result.item.galleryLength = result.item.gallery.length;
                    // }

                    // if (result && result.item && result.item.shortDescription) {
                    //     if (result.item.shortDescription.split(',')[0] == 'Drage koleginice i kolege' || result.item.shortDescription.split(',')[0] == 'Drage koleginice' || result.item.shortDescription.split(',')[0] == 'Drage kolege') {
                    //         result.item.checkIntro = true;
                    //     }
                    // }
                    return {
                        data: result.item,
                        // gallery: result.item.gallery ? result.item.gallery : [],
                    }
                })

            },

            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/home', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        home: data
                    }
                })

            }


        ]

    },

    {
        path: "/arhiva-novosti",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Arhiva novosti'.translate(lang) + ' - Interni portal EuroExpress brza pošta'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://interni.euroexpress.ba/share.png'
            }
        },
        component: BlogPage,
        loadData: [
            // (fetchFunction, lang, match) => {
            //     return fetchFunction(`https://interni-api.euroexpress.ba/latest/articles/${match.params.category}`, {
            //         method: 'GET',
            //         headers: {
            //             'content-type': 'application/json'
            //         },

            //     }).then(res => res.json()).then((result) => {
            //         for(let i=0; i<result.length; i++){
            //             result[i].dateTS = new Date(result[i].date + " " + result[i].time).getTime()/1000;
            //         }
            //         return {
            //             blogLatest: result,
            //         }
            //     })

            // },
            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/home', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        home: data
                    }
                })

            }

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(`https://interni-api.euroexpress.ba/articlesAll`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        lang: lang
                    })
                }).then(res => res.json()).then((result) => {
                    for (let i = 0; i < result.items.length; i++) {
                        result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
                    }
                    return {
                        page: query.page ? query.page : 0,
                        items: result.items,
                        total: result.total,

                    }
                })

            }
        ]


    },

    {
        path: "/podesavanja",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Podešavanja'.translate(lang) + ' - Interni portal EuroExpress brza pošta'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://interni.euroexpress.ba/share.png'
            }
        },
        component: ChangePassword,
        loadData: [

            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/home', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        home: data
                    }
                })

            }

        ],
        loadDataWithQuery: [

        ]


    },

    {
        path: "/interne-novosti/interna-biblioteka",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Interna biblioteka'.translate(lang) + ' - Interni portal EuroExpress brza pošta'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://interni.euroexpress.ba/share.png'
            }
        },
        component: Library,
        loadData: [

            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/home', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        home: data
                    }
                })

            }

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(`https://interni-api.euroexpress.ba/library`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        lang: lang
                    })
                }).then(res => res.json()).then((result) => {
                    // for(let i=0; i<result.items.length; i++){
                    //     result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime()/1000;
                    // }
                    return {
                        page: query.page ? query.page : 0,
                        items: result.items,
                        total: result.total,
                    }
                })

            }
        ]


    },
    {
        path: "/predstavljanje-novozaposlenih",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Predstavljanje zaposlenih'.translate(lang) + ' - Interni portal EuroExpress brza pošta'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://interni.euroexpress.ba/share.png'
            }
        },
        component: Employees,
        loadData: [

            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/home', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        home: data
                    }
                })

            },




        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {

                return fetchFunction(`https://interni-api.euroexpress.ba/employees`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        lang: lang,
                        active: query.active ? query.active : 0,
                    })
                }).then(res => res.json()).then((result) => {
                    for (let i = 0; i < result.items.length; i++) {
                        result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
                    }
                    return {
                        page: query.page ? query.page : 0,
                        active: query.active ? query.active : 0,
                        items: result.items,
                        total: result.total,
                    }
                })

            }
        ]


    },
    {
        path: "/:category/novosti/:submenu",
        generateSeoTags: (data, lang) => {
            return {
                title: data.submenuName + ' - Interni portal EuroExpress brza pošta'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://interni.euroexpress.ba/share.png'
            }
        },
        component: BlogPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://interni-api.euroexpress.ba/latest/articles/${match.params.category}/${match.params.submenu}/0`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {
                    for (let i = 0; i < result.length; i++) {
                        result[i].dateTS = new Date(result[i].date + " " + result[i].time).getTime() / 1000;
                    }
                    return {
                        blogLatest: result,
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/home', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        home: data
                    }
                })

            }

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(`https://interni-api.euroexpress.ba/articles/${match.params.category}/${match.params.submenu}`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        lang: lang
                    })
                }).then(res => res.json()).then((result) => {
                    for (let i = 0; i < result.items.length; i++) {
                        result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
                    }
                    return {
                        page: query.page ? query.page : 0,
                        items: result.items,
                        total: result.total,
                        submenuName: result.submenuName
                    }
                })

            }
        ]


    },

    {
        path: "/mjere-predostroznosti",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Novosti'.translate(lang) + ' - Interni portal EuroExpress brza pošta'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://interni.euroexpress.ba/share.png'
            }
        },
        component: PrecautionsPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://interni-api.euroexpress.ba/precautions`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        data: result,
                    }
                })

            },

            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/home', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        home: data
                    }
                })

            }

        ]

    },

    {
        path: '/novi-clan',
        generateSeoTags: (data, lang) => {
            return {
                title: 'Novi član' + ' - Interni portal EuroExpress brza pošta'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://interni.euroexpress.ba/share.png'
            }
        },
        component: NewMemberPreview,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/home', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        home: data
                    }
                })

            },


        ]

    },


    {
        path: "/anketa",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Anketa' + ' - Interni portal EuroExpress brza pošta'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://interni.euroexpress.ba/share.png'
            }
        },
        component: PoolPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/home', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        home: data
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/pools/all', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${typeof window != 'undefined' ? localStorage.getItem('token') : null}`
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        pool: data,
                        answers: data.answers,
                        comments: data.comments,
                        showCommentBox: !data.userCommented && data.userVoted ? true : false
                    }
                })

            },

        ]

    },
    {
        path: "/anketa-slike",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Anketa' + ' - Interni portal EuroExpress brza pošta'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://interni.euroexpress.ba/share.png'
            }
        },
        component: PoolImagePage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/home', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        home: data
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/pools-images/all', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        pool: data,
                        answers: data.answers,
                        comments: data.comments
                    }
                })

            },

        ]

    },
    {
        path: "/anketa-video",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Anketa' + ' - Interni portal EuroExpress brza pošta'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://interni.euroexpress.ba/share.png'
            }
        },
        component: PoolVideoPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/home', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        home: data
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/pools-video/all', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        pool: data,
                        answers: data.answers,
                        comments: data.comments
                    }
                })

            },

        ]

    },

    {
        path: "/:category/novosti/:submenu/:alias",
        generateSeoTags: (data, lang) => {
            return {
                title: (data.data && data.data.name ? data.data.name : 'Novosti') + ' - Interni portal EuroExpress brza pošta'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://interni.euroexpress.ba/share.png'
            }
        },
        component: BlogDetailPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://interni-api.euroexpress.ba/articles/${match.params.category}/${match.params.submenu}/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    result.item.dateTS = new Date(result.item.date + " " + result.item.time).getTime() / 1000;
                    if (result && result.item && result.item.gallery) {
                        result.item.galleryLength = result.item.gallery.length;
                    }

                    if (result && result.item && result.item.shortDescription) {
                        if (result.item.shortDescription.split(',')[0] == 'Drage koleginice i kolege' || result.item.shortDescription.split(',')[0] == 'Drage koleginice' || result.item.shortDescription.split(',')[0] == 'Drage kolege') {
                            result.item.checkIntro = true;
                        }
                    }
                    return {
                        data: result.item,
                        gallery: result.item.gallery ? result.item.gallery : [],
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://interni-api.euroexpress.ba/latest/articles/${match.params.category}/${match.params.submenu}/${match.params.alias}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    for (let i = 0; i < result.items.length; i++) {
                        result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
                    }

                    return {
                        blogLatest: result.items,
                        category: result.category
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/home', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        home: data
                    }
                })

            }


        ]

    },


    {
        path: "/dostavne-linije",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Dostavne linije'.translate(lang) + ' - Interni portal EuroExpress brza pošta'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://interni.euroexpress.ba/share.png'
            }
        },
        component: Service1Page,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/home', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        home: data
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/deliveryLines', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        delivery: data.deliveryLines
                    }
                })

            }
            // (fetchFunction, lang, match) => {
            //     return fetchFunction('https://interni-api.euroexpress.ba/documents/all', {
            //         method: 'GET',
            //         headers: {
            //             'content-type': 'application/json'
            //         },

            //     }).then(res => res.json()).then((result) => {

            //         return {
            //             documents: result,
            //         }
            //     })

            // }
            // (fetchFunction, lang, match) => {
            //     return fetchFunction(`https://interni-api.euroexpress.ba/services/1/${lang}/${match.params.alias}`, {
            //         method: 'GET',
            //         headers: {
            //             'content-type': 'application/json'
            //         },

            //     }).then(res => res.json()).then((result) => {

            //         return {
            //             data: result,
            //         }
            //     })

            // },



        ]
    },
    {
        path: "/kontakt",
        generateSeoTags: (data, lang) => {
            return {
                title: 'Sugestije zaposlenih'.translate(lang) + ' - Interni portal EuroExpress brza pošta'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://interni.euroexpress.ba/share.png'
            }
        },
        component: ContactPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/home', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    console.log(data)
                    return {
                        home: data
                    }
                })

            }
        ]

    },
    {
        path: "/:category/dokumenti",
        generateSeoTags: (data, lang) => {
            console.log(data)
            return {
                title: data.pageTitle + ' - Interni portal EuroExpress brza pošta'.translate(lang),
                description: ''.translate(lang),
                'og:image': 'https://interni.euroexpress.ba/share.png'
            }
        },
        component: DocumentPage,
        loadData: [
            (fetchFunction, lang, match) => {
                return fetchFunction(`https://interni-api.euroexpress.ba/documents/${match.params.category}`, {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    },

                }).then(res => res.json()).then((result) => {

                    return {
                        documents: result,
                    }
                })

            },
            (fetchFunction, lang, match) => {
                return fetchFunction('https://interni-api.euroexpress.ba/home', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((data) => {
                    return {
                        home: data
                    }
                })

            }


        ]
    },



];

export function generateRoutes(r) {
    let newRoutes = [];
    for (let i = 0; i < r.length; i++) {
        newRoutes.push(r[i]);

        newRoutes.push({
            ...r[i],
            path: linkTranslates.en[r[i].path]
        })
    }
    return newRoutes;
}