import React, { Component } from 'react';
import Link from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import Slider from '../components/slider';

import { Redirect } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';


import weather from '../assets/weather.svg';
import evidency from '../assets/evidency.png';
import sugestije from '../assets/sugestije.png';
import app from '../assets/app.png';
import b1 from '../assets/b1.png';

import details_naslovna from '../assets/details-naslovna.jpg';


import help_icon from '../assets/help-icon.svg';
import warning_icon from '../assets/warning-icon.svg';
import mail_icon from '../assets/mail-icon.svg';
import chart_icon from '../assets/chart-icon.svg';
import star from '../assets/star.svg';
import like from '../assets/like.svg';

import book_1 from '../assets/book-1.png';
import team_member from '../assets/team-member.png';
import moment from 'moment';

import RightSidebar from '../components/rightSidebar';

import { API_ENDPOINT } from '../constants';


class BlogDetailPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }

        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }
        }


    }



    render() {

        return (
            <div>
                {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null
                }
                <section id="content">
                    <Container>
                        <Row>
                            <Col lg="9" className="nop">
                                <div className="content l-0 r-fix">
                                    <div className="content-container">
                                        <article className="article-details">
                                            {/* <a className="category" href="#">{this.state.data && this.state.data.categoryName}</a> */}
                                            <h1>{this.state.data && this.state.data.name}</h1>
                                            {/* <span className="date">29. April, 2020</span> */}
                                            {/* <span className="date">{moment.unix(this.state.data && this.state.data.tsCreated).format('DD. MMMM, YYYY')}</span> */}
                                            {/* <p>Od ponedeljka 31. avgusta 2020. godine je poslovnica EuroExpress brze pošte u Živinicama na
                                    novoj adresi. Vrata nove poslovnice su otvorena na adresi Druga ulica do broja 53.</p> */}
                                            {/* <p>{this.state.data && this.state.data.shortDescription}</p> */}
                                            {/* <img src={details_naslovna} alt="EuroExpress" /> */}
                                            {/* <p>EuroExpress brza pošta posluje na teritoriji opštine Živinice od 1. jula 2017. godine, te su tokom tri
                                                godine rada ostvarili odlične rezultate poslovanje u navedenoj opštini. Rukovodilac
                                                Paket Shop-ova u kompaniji EuroExpress brza pošta, Sandra Jokanović je povodom otvaranja
                                                nove poslovnice izjavila da je veliko zadovoljstvo što je poslovnica prešla u veći prostor koji
                                                korisnicima usluga sada obezbjeđuje i parking prostor. „Naša poslovnica u Živinicama posluje
                                                veoma uspješno već pune tri godine, te je na osnovu poslovnih rezultat i prometa donesena
                                                odluka da pređemo u veći prostor koji smo uredili po našim novim standardima vizuelnog
                                                identiteta. Korisnici naših usluga nas danas mogu posjetiti u većem ljepšem prostoru, koji je
                                                prilagođen njihovim zahtjevima.“
                                    <br/><br/>
                                                        Radno vrijeme poslovnice je svakim radnim danom od 9 do 17:30 časova.
                                </p> */}
                                            {
                                                this.state.data ?
                                                    <div dangerouslySetInnerHTML={{ __html: this.state.data.content }}></div>
                                                    :
                                                    null
                                            }

                                            {/* <p>{this.state.data && this.state.data.content}</p> */}
                                        </article>
                                    </div>
                                    <Col lg="12">
                                        <Row className="mb-25">
                                            <Col lg="12">
                                                <div className="other-news-title">
                                                    {
                                                        this.state.category && this.state.blogLatest.length > 0 ?
                                                            <h5 className="section-title">Ostalo iz {this.state.category}</h5>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </Col>
                                            {
                                                this.state.blogLatest && this.state.blogLatest.map((item, idx) => {
                                                    return (
                                                        <Col lg="4" md="4">
                                                            <a href={`/${item.menuCategory}/novosti/${item._id}`}>
                                                                <article>
                                                                    <div className="article-header bg-1"></div>
                                                                    <div className="article-body">
                                                                        <a href="#">{item.categoryName}</a>
                                                                        <h3>{item.name}</h3>
                                                                        <span>{moment.unix(item && item.tsCreated).format('DD. MMMM, YYYY')}</span>
                                                                    </div>
                                                                </article>
                                                            </a>
                                                        </Col>
                                                    )
                                                })
                                            }

                                        </Row>
                                    </Col>

                                </div>
                            </Col>
                            <Col lg="3" className="nop">
                                {
                                    this.state.home && this.state.home.statistics ?
                                        <RightSidebar
                                            totalVehicles={this.state.home.statistics.totalVehicles}
                                            newMembers={this.state.home.statistics.newMembers}
                                            deliveryLines={this.state.home.statistics.deliveryLines}
                                            warehouses={this.state.home.statistics.warehouses}
                                            shops={this.state.home.statistics.shops}
                                            googlePlayLink={this.state.home.googlePlayLink}
                                            appStoreLink={this.state.home.appStoreLink}
                                            totalEmployess={this.state.home.statistics.totalEmployess}
                                        />
                                        :
                                        null
                                }
                            </Col>
                        </Row>
                    </Container>

                </section>

            </div>
        );
    }
}

export default Page(BlogDetailPage);