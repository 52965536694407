import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
// import { Link, Redirect } from 'react-router-dom';

import Check from './fields/check';
import Text from './fields/text';
import Textarea from './fields/textarea';
import rightChevron from '../../assets/svg/right-chevron.svg';
import Isvg from 'react-inlinesvg';
import Link from '../../components/link';
import arrows_right from '../../assets/arrows-right.png';
import see_password from '../../assets/svg/see-password.svg';
import eye from '../../assets/eye.svg';

import Image from './fields/image';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderCheckField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },

}) => (

    <Check
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        // multilang={multilang}
        // lang={lang}

        {...input}
    />
)
const renderImageField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => (

    <Image
        placeholder={placeholder}
        errorText={touched && error}
        error={touched && error}

        {...input}
    />
)


const renderTextField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type
}) => (

    <Text
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        {...input}
    />
)

const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

    <Textarea
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)


class form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            
        }
    }



    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props;

        return (
            <form onSubmit={handleSubmit} className="sugestion-form none-box-shadow">
                <Row>

                    <Col lg="12">
                        <div className="field-wrap">
                            <label>Profilna slika</label>
                            <Field
                                name="image"
                                component={renderImageField}

                            ></Field>
                        </div>



                    </Col>

                    <Col lg="12" className="btn-wrap">

                        {this.props.loading ?
                            null
                            :
                            <button className="button">{'Sačuvaj'.translate(this.props.lang)}</button>
                        }
                    </Col>


                </Row>


            </form>

        )
    }
}

export default reduxForm({
    form: 'form2'  // a unique identifier for this form
})(form)