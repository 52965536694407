import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Button
} from 'reactstrap';


import moment from 'moment';

import { Player, Controls } from "@lottiefiles/react-lottie-player";

import no_content from '../assets/images/no_content.png';

import arrow_left from '../assets/arrow-left.svg';
import list_1 from '../assets/list-1.svg';
import list_2 from '../assets/list-2.svg';
import arrow_right from '../assets/arrow-right.svg';
import weather from '../assets/weather.svg';
import evidency from '../assets/evidency.png';
import sugestije from '../assets/sugestije.png';
import app from '../assets/app.png';
import b1 from '../assets/b1.png';
import shape1 from '../assets/svg/shape-1.svg';
import shape2 from '../assets/svg/shape-2.svg';
import noImage from '../assets/no-image.png';


import details_naslovna from '../assets/details-naslovna.jpg';

import ReactPaginate from 'react-paginate';
import RightSidebar from '../components/rightSidebar';
import { API_ENDPOINT } from '../constants';

import { Redirect } from 'react-router-dom';


var striptags = require('striptags');

class BlogPage extends Component {
    constructor(props) {
        super(props);
        this.updateStateFromSearch = this.updateStateFromSearch.bind(this);
        this.updateParam = this.updateParam.bind(this);
        this.get = this.get.bind(this);

        this.state = {
            promotedProjects: [],
            page: 0,
            view: 0,
            checked: false,
            ...props.initialData
        };
    }

    updateStateFromSearch(callback) {
        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = { page: 0 };
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        this.setState({
            category: null,
            tag: null,
            items: []
        }, () => {
            this.setState(params, callback);

        })

    }


    updateParam(name, value) {

        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        params[name] = value;

        let paramsArr = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {

                paramsArr.push(key + "=" + params[key]);
            }
        }



        let search = '?' + paramsArr.join('&');


        this.props[0].history.push(this.props[0].location.pathname + search);
        ////console.log(search);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.updateStateFromSearch(() => {
                this.get()
            });
        }
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.get()
        }
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
        setTimeout(() => {
            this.checkVote()
        }, 1000);
        // this.checkVote()

        fetch(`https://interni-api.euroexpress.ba/log/new`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                path: this.props[0].location.pathname,
            })
        }).then(res => res.json()).then((result) => {

        })




        this.updateStateFromSearch(this.get);


    }
    get() {



    }

    checkVote = () => {
        if (this.state.pool) {

            fetch('https://interni-api.euroexpress.ba/check/vote-images/' + this.state.pool._id + '/' + this.props.user._id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',

                }
            }).then((res) => res.json()).then((result) => {
                this.setState({
                    answeredVote: result.answered,
                    checked: true
                })
                if (result.answeredVote) {
                    this.setState({
                        answerIDX: result.answer
                    })
                }
            })

        }

    }

    vote = (id, idx) => {

        if (this.props.user && idx != null) {
            let obj = {
                poolId: id,
                poolVote: idx,
                userId: this.props.user._id
            }

            fetch('https://interni-api.euroexpress.ba/pool/vote-images', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify(obj)
            }).then((res) => res.json()).then((result) => {
                this.setState({
                    formDone: true,
                    sentVoteModal: id
                })
            })
        }


    }

    sendComment = (id, comment) => {
        if (this.props.user) {
            let obj = {
                poolId: id,
                comment: comment,
                userId: this.props.user._id
            }

            fetch('https://interni-api.euroexpress.ba/pool/comment-images', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify(obj)
            }).then((res) => res.json()).then((result) => {
                this.setState({
                    comment: null,
                    sentVoteModal: null
                })
                window.location.reload()
            })
        }
    }



    render() {
        // console.log(this.state.answeredVote, this.state.answers)

        return (
            <div>
                {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null
                }
                <img src={shape1} className="shape-1" />
                <img src={shape2} className="shape-2" />
                <section id="content">
                    <Container>
                        <Row>
                            <Col lg="9" className="nop">
                                <div className="content l-0 r-fix pd0">
                                    <div className="survey-page">
                                        <h5 className="section-title">Anketa</h5>
                                        <p>{this.state.pool && this.state.pool.name}</p>
                                        {/* <form>
                                            {
                                                this.state.answeredVote ?
                                                    this.state.answers && this.state.answers.map((item, idx) => {
                                                        if (item != "")
                                                            return (
                                                                <div className="form-group survey-center" key={idx}>
                                                                    <div className="bar">
                                                                        <div className={item.maxElement ? "bar-blue" : "bar-red"} style={{ width: item.votesPercent + '%' }}></div>

                                                                    </div>

                                                                    <div className='pools-images-image-wrap'>
                                                                        <a href={API_ENDPOINT + item.answer} target="_blank">
                                                                            <img src={API_ENDPOINT + item.answer} />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            )
                                                    })

                                                    :
                                                    null
                                                this.state.answers && this.state.answers.map((item, idx) => {
                                                    if (item != "")
                                                        return (
                                                            <div className="form-group survey-center" key={idx}>
                                                                <div style={{ marginLeft: 20, marginRight: 20 }}>
                                                                    <div className={this.state.voteItem == idx ? "vote-box active-vote" : "vote-box"} onClick={() => this.setState({ voteItem: idx })}>

                                                                    </div>
                                                                </div>


                                                                <div className='pools-images-image-wrap'>
                                                                    <a href={API_ENDPOINT + item.answer} target="_blank">
                                                                        <img src={API_ENDPOINT + item.answer} />
                                                                    </a>
                                                                </div>

                                                            </div>
                                                        )
                                                })
                                            }


                                        </form> */}
                                        {
                                            this.state.answeredVote && this.state.checked ?
                                                <Row style={{ marginTop: 15 }}>
                                                    {
                                                        this.state.answers && this.state.answers.map((item, idx) => {
                                                            console.log(item)
                                                            if (item != "")
                                                                return (
                                                                    <Col lg='6'>
                                                                        <div className='answered-survey-images-item-wrap' >
                                                                            <div className='pools-images-image-wrap'>
                                                                                <img src={API_ENDPOINT + item.answer} />
                                                                            </div>
                                                                            <div className='footer-wrap'>
                                                                                <div className='bar-wrap'>
                                                                                    <div className={item.maxElement ? "bar-blue" : "bar-red"} style={{ width: item.votesPercent + '%' }}></div>
                                                                                </div>
                                                                                <div className='number-of-votes-preview'>
                                                                                    <p>{item.numOfVotes} <span>({item.votesPercentTwoDecimals}%)</span></p>
                                                                                </div>
                                                                            </div>



                                                                        </div>
                                                                    </Col>

                                                                )
                                                        })
                                                        //  this.state.answers && this.state.answers.map((item, idx) => {
                                                        //     if (item != "")
                                                        //         return (
                                                        //             <div className="form-group survey-center" key={idx}>
                                                        //                 <div style={{ marginLeft: 20, marginRight: 20 }}>
                                                        //                     <div className={this.state.voteItem == idx ? "vote-box active-vote" : "vote-box"} onClick={() => this.setState({ voteItem: idx })}>

                                                        //                     </div>
                                                        //                 </div>


                                                        //                 <div className='pools-images-image-wrap'>
                                                        //                     <a href={API_ENDPOINT + item.answer} target="_blank">
                                                        //                         <img src={API_ENDPOINT + item.answer} />
                                                        //                     </a>
                                                        //                 </div>

                                                        //             </div>
                                                        //         )
                                                        // })
                                                    }

                                                </Row>
                                                :
                                                null
                                        }
                                        {
                                            !this.state.answeredVote && this.state.checked ?
                                                <Row style={{ marginTop: 15 }}>
                                                    {
                                                        this.state.answers && this.state.answers.map((item, idx) => {
                                                            if (item != "")
                                                                return (
                                                                    <Col lg='6'>
                                                                        <div className={this.state.voteItem === idx ? 'survey-images-item-wrap selected-survey-item' : 'survey-images-item-wrap'} onClick={() => this.setState({ voteItem: idx })}>
                                                                            <div className='pools-images-image-wrap'>
                                                                                <img src={API_ENDPOINT + item.answer} />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    // <div className="form-group survey-center" key={idx}>
                                                                    //     <div style={{ marginLeft: 20, marginRight: 20 }}>
                                                                    //         <div className={this.state.voteItem == idx ? "vote-box active-vote" : "vote-box"} onClick={() => this.setState({ voteItem: idx })}>
                                                                    //         </div>
                                                                    //     </div>


                                                                    // <div className='pools-images-image-wrap'>
                                                                    //     <a href={API_ENDPOINT + item.answer} target="_blank">
                                                                    //         <img src={API_ENDPOINT + item.answer} />
                                                                    //     </a>
                                                                    // </div>

                                                                    // </div>
                                                                )
                                                        })
                                                    }
                                                </Row>
                                                :
                                                null
                                        }
                                        <div className="form-group survey-center bgct cc">
                                            <div className="votes"></div>

                                            {
                                                this.state.answeredVote && this.state.checked ?
                                                    <div className="votes">
                                                        {this.state.pool && this.state.pool.votesNumber}
                                                        {
                                                            this.state.pool && this.state.pool.votesNumber ?
                                                                Number(String(this.state.pool.votesNumber).charAt(String(this.state.pool.votesNumber).length - 1)) == 1 && Number(String(this.state.pool.votesNumber).charAt(String(this.state.pool.votesNumber).length - 2)) != 1 ?
                                                                    <> glas</>
                                                                    :
                                                                    (Number(String(this.state.pool.votesNumber).charAt(String(this.state.pool.votesNumber).length - 1)) == 2 || Number(String(this.state.pool.votesNumber).charAt(String(this.state.pool.votesNumber).length - 1)) == 3 || Number(String(this.state.pool.votesNumber).charAt(String(this.state.pool.votesNumber).length - 1)) == 4) && Number(String(this.state.pool.votesNumber).charAt(String(this.state.pool.votesNumber).length - 2)) != 1 ?
                                                                        <> glasa</>
                                                                        :
                                                                        <> glasova</>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    : (this.state.voteItem || this.state.voteItem === 0) && this.state.checked ?
                                                        <div style={{ width: '100%' }}>
                                                            <button type="submit" onClick={() => {
                                                                this.vote(this.state.pool._id, this.state.voteItem)
                                                            }}>GLASAJ</button>
                                                        </div>
                                                        :
                                                        null
                                            }

                                        </div>

                                    </div>
                                    <div className="survey-comments">
                                        {
                                            this.state.answeredVote && this.state.checked && this.state.comments && this.state.comments.map((item, idx) => {
                                                return (
                                                    <div className="comment">
                                                        <div className="comment-user">
                                                            {
                                                                item && item.userProfile && item.userProfile.image ?
                                                                    <img src={API_ENDPOINT + item.userProfile.image} />
                                                                    :
                                                                    <img src={noImage} />
                                                            }
                                                            {
                                                                item && item.userProfile && item.userProfile.name ?
                                                                    <h6>{item.userProfile.name}</h6>
                                                                    :
                                                                    null
                                                            }
                                                        </div>

                                                        <div className="comment-text">
                                                            <p>{item.comment}</p>

                                                            <div className="comment-ts">
                                                                <h5>{moment.unix(item.timestamp).format('DD.MM.YYYY. HH:mm')}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col lg="3" className="nop">
                                {
                                    this.state.home && this.state.home.statistics ?
                                        <RightSidebar
                                            totalVehicles={this.state.home.statistics.totalVehicles}
                                            newMembers={this.state.home.statistics.newMembers}
                                            deliveryLines={this.state.home.statistics.deliveryLines}
                                            warehouses={this.state.home.statistics.warehouses}
                                            shops={this.state.home.statistics.shops}
                                            googlePlayLink={this.state.home.googlePlayLink}
                                            appStoreLink={this.state.home.appStoreLink}
                                            totalEmployess={this.state.home.statistics.totalEmployess}
                                        />
                                        :
                                        null
                                }
                            </Col>
                        </Row>
                    </Container>

                </section>

                {
                    this.state.sentVoteModal ?
                        <Modal isOpen={this.state.sentVoteModal} centered className="new-member-modal">
                            <ModalHeader><h6>Uspješno ste glasali</h6></ModalHeader>

                            {
                                this.state.comment != null ?
                                    <ModalBody>
                                        <Input type='textarea' value={this.state.comment} onChange={(e) => this.setState({ comment: e.target.value })} placeholder={'Unesite komentar...'} style={{ height: 150 }} />
                                    </ModalBody>
                                    :
                                    null
                            }

                            <ModalFooter className='buttons-right-blue'>
                                {
                                    this.state.comment != null ?
                                        <>
                                            <Button color='primary' onClick={() => this.sendComment(this.state.sentVoteModal, this.state.comment)}>{'Pošalji komentar'.translate(this.props.lang)}</Button>
                                            <Button color='primary' onClick={() => this.setState({ sentVoteModal: !this.state.sentVoteModal }, () => window.location.reload(false))}>{'Zatvori'.translate(this.props.lang)}</Button>
                                        </>
                                        :
                                        null
                                }
                                {
                                    this.state.comment == null ?
                                        <>
                                            <Button color='primary' onClick={() => this.setState({ comment: '' })}>{'Ostavite komentar'.translate(this.props.lang)}</Button>
                                            <Button color='primary' onClick={() => this.setState({ sentVoteModal: !this.state.sentVoteModal }, () => window.location.reload(false))}>{'Zatvori'.translate(this.props.lang)}</Button>
                                        </>
                                        :
                                        null
                                }


                            </ModalFooter>
                        </Modal>
                        :
                        null
                }

            </div>
        );
    }
}

export default Page(BlogPage);