import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import { Redirect } from 'react-router-dom';



import weather from '../assets/weather.svg';
import evidency from '../assets/evidency.png';
import sugestije from '../assets/sugestije.png';
import app from '../assets/app.png';
import b1 from '../assets/b1.png';
import laptop from '../assets/images/laptop.png';
import arrow_left from '../assets/arrow-left.svg';
import arrow_right from '../assets/arrow-right.svg';

import pdf from '../assets/pdf.png';
import word from '../assets/word.png';
import powerPoint from '../assets/powerPoint.png';


import xx from '../assets/xx.png';
import shape1 from '../assets/svg/shape-1.svg';
import shape2 from '../assets/svg/shape-2.svg';

import ReactPaginate from 'react-paginate';

import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

import RightSidebar from '../components/rightSidebar';

import { API_ENDPOINT } from '../constants';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';



var striptags = require('striptags');

class DocumentPage extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);

        this.state = {
            promotedProjects: [],
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();


        this.props[0].match.params.category == "komercijala" ?
            this.setState({ pageTitle: 'Komercijalne ponude' }, () => this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang)))
            :
            this.props[0].match.params.category == "ljudski-resursi" ?
                this.setState({ pageTitle: 'Formulari i obrasci' }, () => this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang)))
                :
                this.setState({ pageTitle: 'Materijali za poslovnu komunikaciju' }, () => this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang)))




    }
    get = () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }

        fetch(`https://interni-api.euroexpress.ba/log/new`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                path: this.props[0].location.pathname,
            })
        }).then(res => res.json()).then((result) => {

        })

    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.get()
        }
    }

    submit(data) {
        // this.setState({
        //     formLoading: true
        // }, () => {

        //     fetch('https://interni-api.euroexpress.ba/contact', {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',

        //         },
        //         body: JSON.stringify(
        //             data
        //         )
        //     }).then((res) => res.json()).then((result) => {
        //         this.setState({
        //             //formLoading: null,
        //             formDone: true
        //         })
        //     })
        // })
    }
    downloadLog = (item) => {


        fetch(`https://interni-api.euroexpress.ba/log/new`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                path: item.icon,
                type: 'download',
                docName: item.name
            })
        }).then(res => res.json()).then((result) => {

        })
    }



    render() {
        return (
            <div>
                {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null
                }
                <img src={shape1} className="shape-1" />
                <img src={shape2} className="shape-2" />
                <section id="content">
                    <Container>
                        <Row>
                            <Col lg="9" className="nop" >
                                <div className="content blue-bg" style={{ paddingRight: 0 }}>
                                    <div className="content-container document-container">
                                        <div className="document-title">
                                            <div className="document-title-left">
                                                {
                                                    this.props[0].match.params.category == "komercijala" ?
                                                        <h6>Komercijalne ponude - obrasci</h6>
                                                        :
                                                        this.props[0].match.params.category == "ljudski-resursi" ?
                                                            <h6>Formulari i obrasci</h6>
                                                            :
                                                            <h6>Materijali za poslovnu komunikaciju</h6>
                                                }
                                            </div>
                                            <div className="breadcumb">
                                                <span>
                                                    <Link to="/">Početna</Link>&nbsp;/&nbsp;<a>{this.props[0].match.params.category == "komercijala" ? "Komerijala" : this.props[0].match.params.category == "ljudski-resursi" ? "Ljudski resursi" : 'Interne novosti'}</a>&nbsp;/&nbsp;<Link to={`/${this.props[0].match.params.category}/dokumenti`} className="b-active">{this.props[0].match.params.category == "komercijala" ? "Komercijalne ponude - obrasci" : this.props[0].match.params.category == "ljudski-resursi" ? "Formulari i obrasci" : "Materijali za poslovnu komunikaciju"}</Link>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="documents">
                                            <div>
                                                <h1>Preuzmite željeni dokument</h1>
                                                <p>Svi dokumenti su organizovani po sekcijama. Za preuzimanje kliknite na ikonicu ili na naslov dokumenta.</p>

                                                <p>Preuzimanje dokumenta će biti automatski pokrenuto.</p>
                                            </div>
                                            <img src={laptop} />
                                        </div>

                                        <div className="documents-files-container">
                                            {
                                                this.props[0].match.params.category == "interne-novosti" ?
                                                    <>
                                                        <div className="document-divider">
                                                            <h3 className="document-subtitle-1">Opšti dokumenti</h3>
                                                        </div>
                                                        <Row>
                                                            {
                                                                this.state.documents && this.state.documents.map((item, idx) => {
                                                                    // if (item.category == "0")
                                                                    return (
                                                                        <Col lg="6">
                                                                            <a href={API_ENDPOINT + item.icon} download={item.name}>
                                                                                <div className="document" onClick={() => this.downloadLog(item)}>
                                                                                    <img src={item.format == 'pdf' ? pdf : item.format == 'exel' ? xx : item.format == 'word' ? word : item.format == 'powerPoint' ? powerPoint : null} alt="" />

                                                                                    <h6>{item.name}</h6>

                                                                                </div>
                                                                            </a>
                                                                        </Col>
                                                                    )
                                                                })
                                                            }
                                                        </Row>
                                                    </>
                                                    :
                                                    this.props[0].match.params.category == "komercijala" ?
                                                        <>
                                                            <div className="document-divider">
                                                                <h3 className="document-subtitle-1"> Komercijalne ponude - obrasci</h3>
                                                            </div>
                                                            <Row>
                                                                {
                                                                    this.state.documents && this.state.documents.map((item, idx) => {
                                                                        return (
                                                                            <Col lg="6">
                                                                                <a href={API_ENDPOINT + item.icon} download={item.name}>
                                                                                    <div className="document" onClick={() => this.downloadLog(item)}>
                                                                                        <img src={item.format == 'pdf' ? pdf : item.format == 'exel' ? xx : item.format == 'word' ? word : item.format == 'powerPoint' ? powerPoint : null} alt="" />

                                                                                        <h6>{item.name}</h6>

                                                                                    </div>
                                                                                </a>
                                                                            </Col>
                                                                        )
                                                                    })
                                                                }
                                                            </Row>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="document-divider">
                                                                <h3 className="document-subtitle-1">Opšti dokumenti</h3>
                                                            </div>
                                                            <Row>
                                                                {
                                                                    this.state.documents && this.state.documents.map((item, idx) => {
                                                                        if (item.category == "0")
                                                                            return (
                                                                                <Col lg="6">
                                                                                    <a href={API_ENDPOINT + item.icon} download={item.name}>
                                                                                        <div className="document" onClick={() => this.downloadLog(item)}>
                                                                                            <img src={item.format == 'pdf' ? pdf : item.format == 'exel' ? xx : item.format == 'word' ? word : item.format == 'powerPoint' ? powerPoint : null} alt="" />

                                                                                            <h6>{item.name}</h6>

                                                                                        </div>
                                                                                    </a>
                                                                                </Col>
                                                                            )
                                                                    })
                                                                }
                                                            </Row>
                                                            <div className="document-divider">
                                                                <h3 className="document-subtitle-1">Kadrovska</h3>
                                                            </div>
                                                            <Row>
                                                                {
                                                                    this.state.documents && this.state.documents.map((item, idx) => {
                                                                        if (item.category == "1")
                                                                            return (
                                                                                <Col lg="6">
                                                                                    <a href={API_ENDPOINT + item.icon} download={item.name} >
                                                                                        <div className="document" onClick={() => this.downloadLog(item)}>
                                                                                            <img src={item.format == 'pdf' ? pdf : item.format == 'exel' ? xx : item.format == 'word' ? word : item.format == 'powerPoint' ? powerPoint : null} alt="pdf icon" />

                                                                                            <h6>{item.name}</h6>

                                                                                        </div>
                                                                                    </a>
                                                                                </Col>
                                                                            )
                                                                    })
                                                                }
                                                            </Row>
                                                        </>
                                            }
                                        </div>




                                    </div>
                                </div>
                            </Col>

                            <Col lg="3" className="nop">
                                {
                                    this.state.home && this.state.home.statistics ?
                                        <RightSidebar
                                            totalVehicles={this.state.home.statistics.totalVehicles}
                                            newMembers={this.state.home.statistics.newMembers}
                                            deliveryLines={this.state.home.statistics.deliveryLines}
                                            warehouses={this.state.home.statistics.warehouses}
                                            shops={this.state.home.statistics.shops}
                                            googlePlayLink={this.state.home.googlePlayLink}
                                            appStoreLink={this.state.home.appStoreLink}
                                            totalEmployess={this.state.home.statistics.totalEmployess}
                                        />
                                        :
                                        null
                                }
                            </Col>
                        </Row>
                    </Container>

                </section>

            </div >
        );
    }
}

export default Page(DocumentPage);