import React, { Component } from 'react';
import Link from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {  Redirect } from 'react-router-dom';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';

import Form from '../components/forms/contactForm';

import arrow_left from '../assets/arrow-left.svg';
import list_1 from '../assets/list-1.svg';
import list_2 from '../assets/list-2.svg';
import arrow_right from '../assets/arrow-right.svg';
import weather from '../assets/weather.svg';
import evidency from '../assets/evidency.png';
import ok from '../assets/svg/ok.svg';
import app from '../assets/app.png';
import b1 from '../assets/b1.png';
import sugestion from '../assets/images/sugestion.png';
import shape1 from '../assets/svg/shape-1.svg';
import shape2 from '../assets/svg/shape-2.svg';

import RightSidebar from '../components/rightSidebar';

import { API_ENDPOINT } from '../constants';

class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);

        this.state = {

        };
    }
    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }

        fetch(`https://interni-api.euroexpress.ba/log/new`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                path: this.props[0].location.pathname,
            })
        }).then(res => res.json()).then((result) => {

        })



        fetch('https://interni-api.euroexpress.ba/menu', {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        }).then(res => res.json()).then((result) => {
            console.log(result);
            this.setState({
                menu: result
            })
        })

        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
    }
    componentDidUpdate(prevProps){
        // if (prevProps[0].location.pathname != this.props[0].location.pathname) {
        //     this.get()
        // }
    }


    submit(data) {

        data.user = this.props.user._id;
        this.setState({
            formLoading: true
        }, () => {

            fetch('https://interni-api.euroexpress.ba/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify(
                    data
                )
            }).then((res) => res.json()).then((result) => {
                this.setState({
                    //formLoading: null,
                    formDone: true
                })
                this.setState({ messageSent: true })
                setTimeout(() => {
                    this.setState({ messageSent: false });
                    window.location.reload(false)
                }, 2000);
            })
        })
    }


    render() {

        return (
            <div>
                 {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null
                }
                <img src={shape1} className="shape-1" />
                <img src={shape2} className="shape-2" />
                <section id="content">
                    <Container>
                        <Row>
                            <Col lg="9" className="nop">
                                <div className="content l-0 r-fix blue-bg" style={{ paddingRight: 0 }}>
                                    <div className="sugestion-container">
                                        <div className="sugestion-title">
                                            <div className="sugestion-title-left">
                                                <h2>Kutak za ideje</h2>
                                            </div>
                                            <div className="breadcumb">
                                                <span>
                                                    <Link to="/">Početna</Link>&nbsp;/&nbsp;<a>Ljudski resursi</a>&nbsp;/&nbsp;<a className="b-active">Kutak za ideje</a>&nbsp;
                                                </span>
                                            </div>
                                        </div>
                                        <div className="sugestion-header">
                                            <div>
                                                <h1>Ostavite svoj prijedlog</h1>
                                                <p>Ukoliko imate bilo kakvu pohvalu, primjedbu ili prijedlog molimo Vas da nam istu pošaljete putem ove forme.</p>
                                            </div>
                                            <img src={sugestion} />
                                        </div>
                                        <Form menu={this.state.menu} onSubmit={this.submit} />
                                        {/* <form className="sugestion-form">
                                            <label for="title">Naslov</label> <br />
                                            <input type="text" name="title" id="title" placeholder="Unesite naslov Vaseg prijedloga" /> <br />
                                            <label for="sector">Sektor</label> <br />
                                            <select name="sector" id="sector">
                                                <option>Izaberite sektor za koji ostavljate prijedlog</option>
                                            </select> <br />
                                            <label for="message">Poruka</label> <br />
                                            <textarea name="message" id="message" cols="30" rows="10"></textarea>
                                            <button type="submit">Posalji prijedlog <span><img src="assets/arrows-right.png" alt="" /></span></button>
                                        </form> */}
                                    </div>
                                </div>
                            </Col>
                            <Col lg="3" className="nop">
                                {
                                    this.state.home && this.state.home.statistics ?
                                        <RightSidebar
                                            totalVehicles={this.state.home.statistics.totalVehicles}
                                            newMembers={this.state.home.statistics.newMembers}
                                            deliveryLines={this.state.home.statistics.deliveryLines}
                                            warehouses={this.state.home.statistics.warehouses}
                                            shops={this.state.home.statistics.shops}
                                            googlePlayLink={this.state.home.googlePlayLink}
                                            appStoreLink={this.state.home.appStoreLink}
                                            totalEmployess={this.state.home.statistics.totalEmployess}
                                        />
                                        :
                                        null
                                }
                            </Col>
                        </Row>
                    </Container>
                    {this.state.messageSent ? (
                        <Modal
                            isOpen={this.state.messageSent}
                            toggle={() =>
                                this.setState({
                                    messageSent: !this.state.messageSent,
                                })
                            }
                            style={{ height: '100px' }}
                        >
                            <ModalHeader
                                style={{ display: 'flex', justifyContent: 'center' }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>

                                    {"Uspješno ste poslali prijedlog..."}
                                    <Isvg src={ok} style={{ marginTop: 20 }} />

                                </div>
                            </ModalHeader>


                        </Modal>
                    ) : null}

                </section>

            </div>
        );
    }
}

export default Page(ContactPage);
