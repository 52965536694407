import React, { Component } from 'react';
// import Link from '../components/link';

import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';



import weather from '../assets/weather.svg';
import evidency from '../assets/evidency.png';
import sugestije from '../assets/sugestije.png';
import app from '../assets/app.png';
import b1 from '../assets/b1.png';

import help_icon from '../assets/help-icon.svg';
import warning_icon from '../assets/warning-icon.svg';
import mail_icon from '../assets/mail-icon.svg';
import chart_icon from '../assets/chart-icon.svg';
import star from '../assets/star.svg';
import like from '../assets/like.svg';
import shape1 from '../assets/svg/shape-1.svg';
import shape2 from '../assets/svg/shape-2.svg';
import ee from '../assets/svg/ee.svg';

import book_1 from '../assets/book-1.png';
import team_member from '../assets/team-member.png';

import PoolModal from '../components/poolModal';
import RightSidebar from '../components/rightSidebar';
import noImage from '../assets/no-image.jpg';

import moment from 'moment';
import { API_ENDPOINT } from '../constants';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Button
} from 'reactstrap';

// baloons import
import bijeliLogo from '../assets/bijeli-logo.png'
import bijeliVjetar from '../assets/bijeli-vjetar.png'
import crveniLogo from '../assets/crveni-logo.png'
import crveniVjetar from '../assets/crveni-vjetar.png'
import plaviLogo from '../assets/plavi-logo.png'
import plaviVjetar from '../assets/plavi-vjetar.png'
import UploadVideoForPool from '../components/uploadVideoForPool';
const baloni = [bijeliLogo, bijeliVjetar, crveniLogo, crveniVjetar, plaviLogo, plaviVjetar]





var striptags = require('striptags');

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.getRandomStyles = this.getRandomStyles.bind(this);
        this.createBaloon = this.createBaloon.bind(this);
        this.removeBalloons = this.removeBalloons.bind(this);

        this.state = {
            promotedProjects: [],
            poolActive: false,
            comment: null,
            ...props.initialData,
            balloonContainerDisabled: true // Remove to release baloons
        };
    }

    // ----------------- baloons -----------------

    balloonContainer = ''


    removeBalloons() {
        if (this.balloonContainer) {
            this.balloonContainer.style.opacity = 0;
            setTimeout(() => {
                this.setState({ balloonContainerDisabled: true })
                this.balloonContainer.remove()
            }, 500)
        }

    }


    getRandomStyles() {
        var dur = this.random(5) + 17;
        var ml = this.random(240);
        var mt = this.random(200);
        return `
  margin: ${mt}px 0 0 ${ml}px;
animation: float ${dur}s ease-in infinite
`;
    }

    random(num) {
        return Math.floor(Math.random() * num);
    }

    createBaloon(num) {
        for (var i = num; i > 0; i--) {
            var balloon = document.createElement("img");
            balloon.className = "balloon";
            balloon.src = baloni[this.random(6)];
            balloon.style.cssText = this.getRandomStyles();
            if (this.balloonContainer)
                this.balloonContainer.append(balloon);
            // console.log("funkcija pozvana jednom", i)
        }
    }

    // ----------------- baloons end -----------------

    componentWillUnmount() {
        window.removeEventListener('click', this.removeBalloons, false);
    }
    componentDidMount() {
        this.balloonContainer = document.getElementById("balloon-container");

        window.addEventListener("click", () => {
            this.removeBalloons();
        });
        this.createBaloon(20)



        setTimeout(() => {
            this.createBaloon(20)
        }, [3000])

        setTimeout(() => {
            this.createBaloon(20)
        }, [5000])






        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        fetch(`https://interni-api.euroexpress.ba/log/new`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                path: this.props[0].location.pathname,
            })
        }).then(res => res.json()).then((result) => {

        })

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }
        setTimeout(() => {
            this.checkVote()
        }, 1000);
        // Banja Luka - cityID: 3204541
        fetch('https://api.openweathermap.org/data/2.5/weather?id=3204541&appid=ead872841a3f922859ce83fabb5b5e39')
            .then(res => res.json()).then((result) => {
                // console.log(result);
                let icon_path = `https://openweathermap.org/img/w/` + result.weather[0].icon + `.png`;
                this.setState({
                    city: result.name,
                    temp: Math.round(result.main.temp - 273),
                    weatherIcon: icon_path
                })
            })
            .catch(function () {
            });

        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.get()
        }
    }
    checkVote = () => {
        if (this.state.pool) {



            fetch('https://interni-api.euroexpress.ba/check/vote/' + this.state.pool._id + '/' + this.props.user._id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',

                }
            }).then((res) => res.json()).then((result) => {
                this.setState({
                    answeredVote: result.answered
                })
                if (result.answeredVote) {
                    this.setState({
                        answerIDX: result.answer
                    })
                }
            })

        }

    }
    vote = (id, idx) => {

        if (this.props.user && idx != null) {
            let obj = {
                poolId: id,
                poolVote: idx,
                userId: this.props.user._id
            }

            fetch('https://interni-api.euroexpress.ba/pool/vote', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify(obj)
            }).then((res) => res.json()).then((result) => {
                this.setState({
                    //formLoading: null,
                    formDone: true,
                    sentVoteModal: id
                })
                // window.location.reload(false)
            })
        }


    }
    sendComment = (id, comment) => {
        if (this.props.user) {
            let obj = {
                poolId: id,
                comment: comment,
                userId: this.props.user._id
            }

            fetch('https://interni-api.euroexpress.ba/pool/comment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${typeof window != 'undefined' ? localStorage.getItem('token') : null}`

                },
                body: JSON.stringify(obj)
            }).then((res) => res.json()).then((result) => {
                this.setState({
                    comment: null,
                    sentVoteModal: null
                })
                this.props[0].history.push('/anketa')
            })
        }
    }



    submit(data) {
        // this.setState({
        //     formLoading: true
        // }, () => {

        //     fetch('https://interni-api.euroexpress.ba/contact', {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',

        //         },
        //         body: JSON.stringify(
        //             data
        //         )
        //     }).then((res) => res.json()).then((result) => {
        //         this.setState({
        //             //formLoading: null,
        //             formDone: true
        //         })
        //     })
        // })
    }



    render() {
        return (
            <div>
                {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null
                }
                <img src={shape1} className="shape-1" />
                <img src={shape2} className="shape-2" />
                {/* {
                    typeof localStorage !== 'undefined' && localStorage.token ? <Redirect to='/' /> : null
                } */}
                <section id="content">
                    <Container>
                        <Row>
                            <Col lg="9" className="nop">
                                <div className="content pd-60-ipad-pro">
                                    <div className="content-container">
                                        <Row>
                                            <Col lg='2' className="mob-show">
                                                <div className="help">
                                                    <div className="help-box">
                                                        <a href={this.state.home && this.state.home.helpDeskLink} target="_blank" >
                                                            <span>
                                                                <img src={help_icon} alt="help" />
                                                            </span>Help Desk</a>
                                                    </div>




                                                    <div className="help-box">
                                                        <a href={this.state.home && this.state.home.emailLink} target="_blank" >
                                                            <span>
                                                                <img src={mail_icon} alt="mail" />
                                                            </span>E-Mail</a>
                                                    </div>
                                                    <div className="help-box" onClick={() => this.setState({ activeTwoLink: true })}>
                                                        <a target="_blank" >
                                                            <span>
                                                                <img src={chart_icon} alt="chart" />
                                                            </span> Sinergis</a>
                                                        <div className={this.state.activeTwoLink ? "dropdown-two-links dropdown-two-links-active" : "dropdown-two-links"}>
                                                            <a href={this.state.home && this.state.home.sinergisLink} target="_blank" >Link 1</a>
                                                            <hr />
                                                            <a href={this.state.home && this.state.home.sinergisLink2} target="_blank" >Link 2</a>
                                                        </div>
                                                    </div>

                                                    <div className="help-box">
                                                        <a href={"https://euroexpress.ba/"} target="_blank" >
                                                            <span>
                                                                <Isvg src={ee} alt="warning" />
                                                            </span></a>
                                                    </div>

                                                </div>
                                            </Col>
                                            <Col lg='10'>
                                                <div className="lastest-title home-lastest">
                                                    <h5 className="section-title">NOVOSTI</h5>
                                                    <Link to="/arhiva-novosti">Arhiva novosti</Link>
                                                </div>
                                                {/* <a href="details.html">
                                                    <div className="lastest-news">
                                                        <div className="lastest-news-content">
                                                            <h6>INTERNI DOGAĐAJI</h6>
                                                            <h1>EuroExpress brza pošta pruža podršku u teškim vremenima</h1>
                                                            <span>29. April, 2020</span>
                                                        </div>
                                                    </div>
                                                </a> */}
                                                {
                                                    this.state.homeLatestArticles ?
                                                        <Link to={`/${this.state.homeLatestArticles[0].menuLink}/novosti/${this.state.homeLatestArticles[0].categoryLink}/${this.state.homeLatestArticles[0]._id}`}>
                                                            <div className="lastest-news">
                                                                <div className="lastest-news-content">

                                                                    <h6>{this.state.homeLatestArticles[0].categoryName}</h6>
                                                                    <h1>{this.state.homeLatestArticles[0].name}</h1>
                                                                    {/* <span>29. April, 2020</span> */}
                                                                    <span>{this.state.homeLatestArticles[0].dateTS ? this.props.translateMonthBlog(this.state.homeLatestArticles[0].dateTS) : null}</span>
                                                                </div>
                                                                <div className="image-wrap">
                                                                    <img src={API_ENDPOINT + this.state.homeLatestArticles[0].image} />
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        :
                                                        null

                                                }


                                            </Col>
                                            <Col lg='2' className="mob-none">
                                                <div className="help">
                                                    <div className="help-box">
                                                        <a href={this.state.home && this.state.home.helpDeskLink} target="_blank" >
                                                            <span>
                                                                <img src={help_icon} alt="help" />
                                                            </span>Help Desk</a>
                                                    </div>
                                                    {/* <div className="help-box">
                                                        <a href={this.state.home && this.state.home.isoToolkitLink} target="_blank" >
                                                            <span>
                                                                <img src={warning_icon} alt="warning" />
                                                            </span>ISO Toolkit</a>
                                                    </div> */}


                                                    <div className="help-box">
                                                        <a href={this.state.home && this.state.home.emailLink} target="_blank" >
                                                            <span>
                                                                <img src={mail_icon} alt="mail" />
                                                            </span>E-Mail</a>
                                                    </div>

                                                    <div className="help-box" onClick={() => this.setState({ activeTwoLink: true })}>
                                                        <a >
                                                            <span>
                                                                <img src={chart_icon} alt="chart" />
                                                            </span> Sinergis</a>
                                                        {/* <div className={this.state.activeTwoLink ? "dropdown-two-links dropdown-two-links-active" : "dropdown-two-links"}> */}
                                                        <div className={"dropdown-two-links dropdown-two-links-active"}>
                                                            <a href={this.state.home && this.state.home.sinergisLink} target="_blank" >Link 1</a>
                                                            <hr />
                                                            <a href={this.state.home && this.state.home.sinergisLink2} target="_blank" >Link 2</a>
                                                        </div>
                                                    </div>
                                                    <div className="help-box">
                                                        <a href={"https://euroexpress.ba/"} target="_blank" >
                                                            <span>
                                                                <Isvg src={ee} alt="warning" />
                                                            </span></a>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="small-desktop-41">
                                            <Col lg="12" className="pr-6">
                                                <Row className="nop margin-bottom20">
                                                    <Col lg='4' md='4'>
                                                        {
                                                            this.state.homeLatestArticles && this.state.homeLatestArticles[1] ?
                                                                <Link to={`/${this.state.homeLatestArticles[1].menuLink}/novosti/${this.state.homeLatestArticles[1].categoryLink}/${this.state.homeLatestArticles[1]._id}`}>
                                                                    <article className="home-article">
                                                                        <div className="article-header bg-1">
                                                                            <div className="image-wrap">
                                                                                <img src={API_ENDPOINT + this.state.homeLatestArticles[1].image} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="article-body">
                                                                            <a href="#">{this.state.homeLatestArticles[1].categoryName}</a>
                                                                            <h3>{this.state.homeLatestArticles[1].name}</h3>
                                                                            <span>{this.state.homeLatestArticles[1].dateTS ? this.props.translateMonthBlog(this.state.homeLatestArticles[1].dateTS) : null}</span>
                                                                        </div>


                                                                    </article>
                                                                </Link>
                                                                :
                                                                null
                                                        }

                                                    </Col>
                                                    <Col lg='4' md='4'>
                                                        {
                                                            this.state.homeLatestArticles && this.state.homeLatestArticles[2] ?
                                                                <Link to={`/${this.state.homeLatestArticles[2].menuLink}/novosti/${this.state.homeLatestArticles[2].categoryLink}/${this.state.homeLatestArticles[2]._id}`}>
                                                                    <article className="home-article">
                                                                        <div className="article-header bg-1">
                                                                            <div className="image-wrap">
                                                                                <img src={API_ENDPOINT + this.state.homeLatestArticles[2].image} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="article-body">
                                                                            <a href="#">{this.state.homeLatestArticles[2].categoryName}</a>
                                                                            <h3>{this.state.homeLatestArticles[2].name}</h3>
                                                                            <span>{this.state.homeLatestArticles[2].dateTS ? this.props.translateMonthBlog(this.state.homeLatestArticles[2].dateTS) : null}</span>
                                                                        </div>
                                                                    </article>
                                                                </Link>
                                                                :
                                                                null
                                                        }
                                                    </Col>
                                                    <Col lg='4' md='4'>
                                                        {
                                                            this.state.homeLatestArticles && this.state.homeLatestArticles[3] ?
                                                                <Link to={`/${this.state.homeLatestArticles[3].menuLink}/novosti/${this.state.homeLatestArticles[3].categoryLink}/${this.state.homeLatestArticles[3]._id}`}>
                                                                    <article className="home-article">
                                                                        <div className="article-header bg-1">
                                                                            <div className="image-wrap">
                                                                                <img src={API_ENDPOINT + this.state.homeLatestArticles[3].image} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="article-body">
                                                                            <a href="#">{this.state.homeLatestArticles[3].categoryName}</a>
                                                                            <h3>{this.state.homeLatestArticles[3].name}</h3>
                                                                            <span>{this.state.homeLatestArticles[3].dateTS ? this.props.translateMonthBlog(this.state.homeLatestArticles[3].dateTS) : null}</span>
                                                                        </div>
                                                                    </article>
                                                                </Link>
                                                                :
                                                                null
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg='7' md='7'>
                                                <div className="survey">
                                                    <h5 className="section-title">Anketa</h5>
                                                    {/* <p>Da li ste zadovoljni radom u EuroExpress brzoj pošti?</p> */}
                                                    <p>{this.state.pool && this.state.pool.name}</p>
                                                    <form>
                                                        {
                                                            this.state.answers && this.state.answers.map((item, idx) => {
                                                                if (item != "")
                                                                    return (
                                                                        <div className="form-group survey-center" key={idx}>
                                                                            {
                                                                                this.state.answeredVote ?
                                                                                    <div className="bar">
                                                                                        <div className={item.maxElement ? "bar-blue" : "bar-red"} style={{ width: item.votesPercent + '%' }}>{item.votesPercent ? (item.votesPercent + '%') : null}</div>

                                                                                    </div>
                                                                                    :
                                                                                    <div className={this.state.voteItem == idx ? "vote-box active-vote" : "vote-box"} onClick={() => this.setState({ voteItem: idx })}>

                                                                                    </div>
                                                                            }



                                                                            <span><span style={{ fontWeight: 600 }}>{item.numOfVotes && this.state.answeredVote ? ('(' + item.numOfVotes + ') ') : null}</span>{item.answer}</span>
                                                                        </div>
                                                                    )
                                                            })
                                                        }

                                                    </form>
                                                    <div className="form-group survey-center bgct cc">
                                                        {
                                                            !this.state.answeredVote ?
                                                                <button type="submit" onClick={() => {
                                                                    // this.setState({ poolActive: this.state.pool && this.state.pool })
                                                                    this.vote(this.state.pool._id, this.state.voteItem)
                                                                }}>Pošalji odgovor</button>
                                                                :
                                                                this.state.comments && this.state.comments.length ?
                                                                    <button type="submit" onClick={() => {
                                                                        this.props[0].history.push(`/anketa`)
                                                                    }}>Komentari</button>
                                                                    :
                                                                    <div className="votes"></div>
                                                        }

                                                        <div className="votes">
                                                            {this.state.pool && this.state.pool.votesNumber}
                                                            {
                                                                this.state.pool && this.state.pool.votesNumber ?
                                                                    Number(String(this.state.pool.votesNumber).charAt(String(this.state.pool.votesNumber).length - 1)) == 1 && Number(String(this.state.pool.votesNumber).charAt(String(this.state.pool.votesNumber).length - 2)) != 1 ?
                                                                        <> glas</>
                                                                        :
                                                                        (Number(String(this.state.pool.votesNumber).charAt(String(this.state.pool.votesNumber).length - 1)) == 2 || Number(String(this.state.pool.votesNumber).charAt(String(this.state.pool.votesNumber).length - 1)) == 3 || Number(String(this.state.pool.votesNumber).charAt(String(this.state.pool.votesNumber).length - 1)) == 4) && Number(String(this.state.pool.votesNumber).charAt(String(this.state.pool.votesNumber).length - 2)) != 1 ?
                                                                            <> glasa</>
                                                                            :
                                                                            <> glasova</>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                                {/* <UploadVideoForPool /> */}
                                            </Col>
                                            <Col lg="5" md='5' className="pl-7">
                                                <Link to="/ljudski-resursi/novosti/edukativni-materijal">
                                                    <div className="education">
                                                        <div className="image-wrap">
                                                            <img src={this.state.home && this.state.home.educationBox && API_ENDPOINT + this.state.home.educationBox.image} />
                                                        </div>
                                                        <h5 className="section-title">{this.state.home && this.state.home.educationBox && this.state.home.educationBox.name}</h5>
                                                    </div>
                                                </Link>
                                            </Col>
                                        </Row>
                                        <Row className="mt-25">
                                            <Col lg="7" md="7">
                                                <div className="libary">
                                                    <h5 className="section-title">INTERNA BIBLIOTEKA</h5>
                                                    <div className="libary-content">
                                                        <img src={this.state.library && API_ENDPOINT + this.state.library.image} alt="" />
                                                        <div className="libary-text">
                                                            {/* <span>PROMOCIJA KNJIGE</span>
                                                            <h6>CRIME THRILLERS</h6>
                                                            <p>EuroExpress brza pošta je krajem
                                                            marta obilježila dvanaest godina
                                                            poslovanja u djelatnosti brzih pošta. </p> */}
                                                            <h6>{this.state.library && this.state.library.name}</h6>
                                                            <p>{this.state.library && this.state.library.shortDescription}</p>
                                                            <a href={this.state.library && API_ENDPOINT + this.state.library.link} target="_blank">Pročitaj detaljnije</a>

                                                        </div>

                                                    </div>
                                                    <div className="archive-library">
                                                        <Link to="/interne-novosti/interna-biblioteka"><span>Arhiva</span></Link>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg="5" md='5' className="pl-7">
                                                <div className="team-home home-member" onClick={() => {
                                                    this.props[0].history.push('/novi-clan');
                                                    // this.setState({ newMemberModal: this.state.home && this.state.home.newMember })
                                                }}>
                                                    <img src={star} className="star" alt="svg" />
                                                    <img src={like} className="like" alt="like" />
                                                    <h5 className="section-title">NOVI ČLAN TIMA</h5>
                                                    {
                                                        this.state.home && this.state.home.newMember && this.state.home.newMember.image ?
                                                            <img src={this.state.home.newMember && API_ENDPOINT + this.state.home.newMember.image} alt="member" className="team-member" />
                                                            :
                                                            <img src={noImage} alt="member" className="team-member" />
                                                    }

                                                    <h6>{this.state.home && this.state.home.newMember && this.state.home.newMember.title}</h6>
                                                    <span>
                                                        <div dangerouslySetInnerHTML={{ __html: this.state.home && this.state.home.newMember && this.state.home.newMember.shortDescription }}></div>
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="3" className="nop">
                                {
                                    this.state.home && this.state.home.statistics ?
                                        <RightSidebar
                                            totalVehicles={this.state.home.statistics.totalVehicles}
                                            newMembers={this.state.home.statistics.newMembers}
                                            deliveryLines={this.state.home.statistics.deliveryLines}
                                            warehouses={this.state.home.statistics.warehouses}
                                            shops={this.state.home.statistics.shops}
                                            googlePlayLink={this.state.home.googlePlayLink}
                                            appStoreLink={this.state.home.appStoreLink}
                                            totalEmployess={this.state.home.statistics.totalEmployess}
                                        />
                                        :
                                        null
                                }

                            </Col>
                        </Row>
                    </Container>
                    {
                        this.state.newMemberModal ?
                            <Modal isOpen={this.state.newMemberModal} centered className="new-member-modal">
                                <ModalHeader>{this.state.newMemberModal.title}</ModalHeader>
                                <ModalBody>
                                    <img src={API_ENDPOINT + this.state.newMemberModal.image} />
                                    <div dangerouslySetInnerHTML={{ __html: this.state.newMemberModal && this.state.newMemberModal.shortDescription }}></div>
                                </ModalBody>
                                <ModalFooter className='buttons-right-blue'>

                                    <Button color='primary' onClick={() => this.setState({ newMemberModal: !this.state.newMemberModal })}>{'Zatvori'.translate(this.props.lang)}</Button>
                                </ModalFooter>
                            </Modal>
                            :
                            null
                    }
                    {
                        this.state.sentVoteModal ?
                            <Modal isOpen={this.state.sentVoteModal} centered className="new-member-modal">
                                <ModalHeader><h6>Uspješno ste glasali</h6></ModalHeader>

                                {
                                    this.state.comment != null ?
                                        <ModalBody>
                                            <Input type='textarea' value={this.state.comment} onChange={(e) => this.setState({ comment: e.target.value })} placeholder={'Unesite komentar...'} style={{ height: 150 }} />
                                        </ModalBody>
                                        :
                                        null
                                }

                                <ModalFooter className='buttons-right-blue'>
                                    {
                                        this.state.comment != null ?
                                            <>
                                                <Button color='primary' onClick={() => this.sendComment(this.state.sentVoteModal, this.state.comment)}>{'Pošalji komentar'.translate(this.props.lang)}</Button>
                                                <Button color='primary' onClick={() => this.setState({ sentVoteModal: !this.state.sentVoteModal }, () => window.location.reload(false))}>{'Zatvori'.translate(this.props.lang)}</Button>
                                            </>
                                            :
                                            null
                                    }
                                    {
                                        this.state.comment == null ?
                                            <>
                                                <Button color='primary' onClick={() => this.setState({ comment: '' })}>{'Ostavite komentar'.translate(this.props.lang)}</Button>
                                                <Button color='primary' onClick={() => this.setState({ sentVoteModal: !this.state.sentVoteModal }, () => window.location.reload(false))}>{'Zatvori'.translate(this.props.lang)}</Button>
                                            </>
                                            :
                                            null
                                    }


                                </ModalFooter>
                            </Modal>
                            :
                            null
                    }

                    {
                        this.state.poolActive ?
                            <PoolModal
                                isOpen={this.state.poolActive}
                                toggle={() => this.setState({ poolActive: null })}
                                handler={(idx) => { this.vote(this.state.poolActive._id, idx); this.setState({ poolActive: null }) }}
                                item={this.state.poolActive}
                            />
                            :
                            null
                    }

                </section>
                {
                    typeof window !== 'undefined' && window.origin && window.origin.indexOf('localhost') === -1 ?
                        <div id='balloon-container' style={this.state.balloonContainerDisabled ? { display: 'none' } : {}}>

                        </div>
                        :
                        null
                }



            </div >
        );
    }
}

export default Page(HomePage);