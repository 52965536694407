import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
// import { Link, Redirect } from 'react-router-dom';

import Check from './fields/check';
import Text from './fields/text';
import Textarea from './fields/textarea';
import rightChevron from '../../assets/svg/right-chevron.svg';
import Isvg from 'react-inlinesvg';
import Link from '../../components/link';
import arrows_right from '../../assets/arrows-right.png';
import see_password from '../../assets/svg/see-password.svg';
import eye from '../../assets/eye.svg';


import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderCheckField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },

}) => (

    <Check
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        // multilang={multilang}
        // lang={lang}

        {...input}
    />
)

const renderTextField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type
}) => (

    <Text
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        {...input}
    />
)

const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

    <Textarea
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)


class form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            seePassword1: false,
            seePassword2: false,
            seePassword3: false
        }
    }



    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props;
        console.log(pristine, submitting);

        return (
            <form onSubmit={handleSubmit} className="sugestion-form none-box-shadow">
                <Row>

                    <Col lg="12">
                    <div className="field-wrap">
                            <div className="password-input">
                                <Field
                                    name="oldPassword"
                                    component={renderTextField}
                                    label={"Trenutna lozinka".translate(this.props.lang)}
                                    placeholder={"Unesite trenutnu lozinku".translate(this.props.lang)}
                                    validate={[required]}
                                    type={this.state.seePassword1 ? "text" : "password"}
                                ></Field>
                                <div className="preview-password" onClick={() => this.setState({ seePassword1: !this.state.seePassword1 })}>
                                    <Isvg src={eye} />
                                </div>
                            </div>
                        </div>


                        
                    </Col>
                    <Col lg="12">

                    <div className="field-wrap">
                            <div className="password-input">
                                <Field
                                    name="newPassword"
                                    component={renderTextField}
                                    label={"Nova lozinka".translate(this.props.lang)}
                                    placeholder={"Unesite novu lozinku".translate(this.props.lang)}
                                    validate={[required]}
                                    type={this.state.seePassword2 ? "text" : "password"}
                                ></Field>
                                <div className="preview-password" onClick={() => this.setState({ seePassword2: !this.state.seePassword2 })}>
                                    <Isvg src={eye} />
                                </div>
                            </div>
                        </div>

                    </Col>
                    <Col lg="12">
                        <div className="field-wrap">
                            <div className="password-input">
                                <Field
                                    name="retypedPassword"
                                    component={renderTextField}
                                    label={"Potvrda nove lozinke".translate(this.props.lang)}
                                    placeholder={"Unesite ponovo novu lozinku".translate(this.props.lang)}
                                    validate={[required]}
                                    type={this.state.seePassword3 ? "text" : "password"}
                                ></Field>
                                <div className="preview-password" onClick={() => this.setState({ seePassword3: !this.state.seePassword3 })}>
                                    <Isvg src={eye} />
                                </div>
                            </div>
                        </div>
                        
                    </Col>
                    <Col lg="12" className="btn-wrap">

                        {this.props.loading ?
                            null
                            :
                            <button className="button">{'Promjeni lozinku'.translate(this.props.lang)}</button>
                        }
                    </Col>


                </Row>


            </form>

        )
    }
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)