import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';


import moment from 'moment';

import { Player, Controls } from "@lottiefiles/react-lottie-player";

import arrow_left from '../assets/arrow-left.svg';
import list_1 from '../assets/list-1.svg';
import list_2 from '../assets/list-2.svg';
import arrow_right from '../assets/arrow-right.svg';
import weather from '../assets/weather.svg';
import evidency from '../assets/evidency.png';
import sugestije from '../assets/sugestije.png';
import app from '../assets/app.png';
import b1 from '../assets/b1.png';
import shape1 from '../assets/svg/shape-1.svg';
import shape2 from '../assets/svg/shape-2.svg';
import noImage from '../assets/no-image.jpg';

import sema from '../assets/sema.png';

import no_content from '../assets/images/no_content.png';

import details_naslovna from '../assets/details-naslovna.jpg';

import ReactPaginate from 'react-paginate';
import RightSidebar from '../components/rightSidebar';
import { API_ENDPOINT } from '../constants';

import { Redirect } from 'react-router-dom';

import star from '../assets/star.svg';
import like from '../assets/like.svg';


var striptags = require('striptags');

class BlogPage extends Component {
    constructor(props) {
        super(props);
        this.updateStateFromSearch = this.updateStateFromSearch.bind(this);
        this.updateParam = this.updateParam.bind(this);
        this.get = this.get.bind(this);

        this.state = {
            promotedProjects: [],
            page: 0,
            view: 0,
            active: 0,
            sector: null,
            ...props.initialData
        };
    }

    updateStateFromSearch(callback) {
        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = { page: 0 };
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        this.setState({
            category: null,
            tag: null,
            items: []
        }, () => {
            this.setState(params, callback);

        })

    }


    updateParam(name, value) {

        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        params[name] = value;

        let paramsArr = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {

                paramsArr.push(key + "=" + params[key]);
            }
        }



        let search = '?' + paramsArr.join('&');


        this.props[0].history.push(this.props[0].location.pathname + search);
        ////console.log(search);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.updateStateFromSearch(() => {
                this.get()
            });
        }
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.get()
        }
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }


        fetch(`https://interni-api.euroexpress.ba/log/new`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                path: this.props[0].location.pathname,
            })
        }).then(res => res.json()).then((result) => {

        })


        this.updateStateFromSearch(this.get);
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

    }
    get() {
        fetch(`https://interni-api.euroexpress.ba/employees`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                page: this.state.page,
                lang: this.props.lang,
                active: this.state.active
            })
        }).then(res => res.json()).then((result) => {

            this.setState({
                items: result.items,
                total: result.total,
            })
        })

        fetch(`https://interni-api.euroexpress.ba/employees/marked`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        }).then(res => res.json()).then((result) => {

            this.setState({
                marked: result.items,

            })
        })

    }




    render() {
        return (
            <div>
                {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null
                }
                <img src={shape1} className="shape-1" />
                <img src={shape2} className="shape-2" />
                <section id="content">
                    <Container>
                        <Row>
                            <Col lg="12" className="nop">
                                <div className="content-3 l-0 r-fix pd0">
                                    <div className="content-container">
                                        <div className="news-list-title">
                                            <div className="title-content">
                                                <h5 className="section-title">Zaposleni</h5>
                                                <div className="breadcumb">
                                                    <span>
                                                        <Link to="/">Početna</Link>&nbsp;/&nbsp;<a>Ljudski resursi</a>&nbsp;/&nbsp;<a className="b-active">Predstavljanje zaposlenih</a>
                                                    </span>


                                                </div>
                                            </div>

                                        </div>

                                        <div className="content-1">
                                            <div style={{marginTop: 20, marginBottom: 20}}>
                                                <img src={sema} />
                                            </div>

                                            {/* <Row className="nop">
                                                {
                                                    (!this.state.items || !this.state.items.length) && (!this.state.marked || !this.state.marked.length) ?
                                                        // <div className="no-content">
                                                        //     <img src={no_content}/>
                                                        //     <p>Stranica u izradi...</p>

                                                        // </div>
                                                        <div className="no-content" >
                                                            <Player
                                                                autoplay={true}
                                                                loop={true}
                                                                src="/lf30_editor_w4hitwma.json"
                                                                style={{ width: "60%" }}
                                                            ></Player>
                                                            <p>Stranica u izradi...</p>
                                                        </div>
                                                        :
                                                        null
                                                }

                                            </Row> */}



                                            {/* <Row className="nop">

                                                {
                                                    this.state.marked && this.state.marked.map((item, idx) => {
                                                        if (item.izdvojeno)
                                                            return (

                                                                <Col lg="12" md='12' className="pl-7 employee-list" style={{ display: 'flex', justifyContent: 'center' }}>
                                                                    <div className="team main-box">
                                                                        
                                                                        <div className="team-member-wrap">
                                                                            {
                                                                                item.image ?
                                                                                    <img src={API_ENDPOINT + item.image} alt="member" className="team-member" />
                                                                                    :
                                                                                    <img src={noImage} alt="member" className="team-member" />
                                                                            }
                                                                        </div>

                                                                        <div className="info-wrap">
                                                                            <h6>{item.name}</h6>
                                                                            <p>
                                                                                {item.workPlace}

                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            )
                                                    })
                                                }


                                            </Row> */}
                                            {/* <Row className="nop chief-wrap">

                                                {
                                                    this.state.marked && this.state.marked.map((item, idx) => {
                                                        if (item.izdvojeno2)
                                                            return (

                                                                <Col lg="2" md='2' className="pl-7 employee-list">
                                                                    <div className={item.activeSec == this.state.active ? "team active-sector-chief" : "team"}>
                                                                       
                                                                        {
                                                                            item.image ?
                                                                                <img src={API_ENDPOINT + item.image} alt="member" className="team-member" />
                                                                                :
                                                                                <img src={noImage} alt="member" className="team-member" />
                                                                        }

                                                                        <h6>{item.name}</h6>
                                                                        
                                                                        <h4>
                                                                            {item.workPlace}
                                                                        </h4>
                                                                        <h4>VPN: {item.vpn}</h4>
                                                                    </div>
                                                                </Col>
                                                            )
                                                    })
                                                }


                                            </Row> */}

                                            {/* <Row className="nop sectors-wrap">
                                                {
                                                    (this.state.items && this.state.items.length) || (this.state.marked && this.state.marked.length) ?


                                                        <>


                                                            <Col lg="2" md="2" sm="12" className="pl-7">
                                                               
                                                            </Col>
                                                            <Col lg="2" md="2" sm="12" className="pl-7">
                                                                <div className={this.state.active == 6 ? 'sector active-sector' : 'sector'} onClick={() => this.setState({ active: 6, page: 0 }, () => this.get())}>
                                                                    <h3>Sektor logistike i transporta</h3>
                                                                </div>
                                                            </Col>
                                                            <Col lg="2" md="2" sm="12" className="pl-7">
                                                                <div className={this.state.active == 3 ? 'sector active-sector' : 'sector'} onClick={() => this.setState({ active: 3, page: 0 }, () => this.get())}>
                                                                    <h3>Sektor ljudskih resursa</h3>
                                                                </div>
                                                            </Col>
                                                            <Col lg="2" md="2" sm="12" className="pl-7">
                                                                <div className={this.state.active == 4 ? 'sector active-sector' : 'sector'} onClick={() => this.setState({ active: 4, page: 0 }, () => this.get())}>
                                                                    <h3>Komercijalni sektor</h3>
                                                                </div>
                                                            </Col>
                                                            <Col lg="2" md="2" sm="12" className="pl-7">
                                                                <div className={this.state.active == 2 ? 'sector active-sector' : 'sector'} onClick={() => this.setState({ active: 2, page: 0 }, () => this.get())}>
                                                                    <h3>Sektor administracije i podrške</h3>
                                                                </div>
                                                            </Col>
                                                            <Col lg="2" md="2" sm="12" className="pl-7">
                                                                <div className={this.state.active == 5 ? 'sector active-sector' : 'sector'} onClick={() => this.setState({ active: 5, page: 0 }, () => this.get())}>
                                                                    <h3>Sektor za marketing i komunikaciju s korisnicima</h3>
                                                                </div>
                                                            </Col>

                                                        </>
                                                        :
                                                        null
                                                }





                                            </Row> */}
                                            {/* <Row className="nop">

                                         
                                                {
                                                    this.state.items && this.state.items.length ?
                                                        <div className="table-container-2">

                                                            <table className="fl-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Ime i prezime</th>
                                                                        <th>Radno mjesto</th>
                                                                        <th>Sektor</th>
                                                                        <th>VPN</th>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {
                                                                        this.state.items && this.state.items.map((item, idx) => {
                                                                            return (
                                                                                <tr>
                                                                                    <td>{item.name}</td>
                                                                                    <td>{item.workPlace}</td>
                                                                                    <td>{item.shortDescription}</td>
                                                                                    <td>{item.vpn}</td>
                                                                                </tr>

                                                                            )
                                                                        })
                                                                    }

                                                                </tbody>
                                                            </table>

                                                        </div>
                                                        :
                                                        null
                                                }
                                            </Row> */}
                                            {/* {
                                                this.state.total / 9 > 1 ?
                                                    <Row>
                                                        <Col lg="12">
                                                            <ReactPaginate
                                                                previousLabel={''.translate(this.props.lang)}
                                                                nextLabel={''.translate(this.props.lang)}
                                                                breakLabel={'...'}
                                                                breakClassName={'break-me'}
                                                                pageCount={this.state.total / 9}
                                                                marginPagesDisplayed={1}
                                                                pageRangeDisplayed={2}
                                                                onPageChange={(page) => { this.updateParam('page', page.selected); window.scrollTo(0, 0); }}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                                hrefBuilder={(page) => { return `?page=${page}` }}
                                                            
                                                            />

                                                        </Col>

                                                    </Row>

                                                    :

                                                    null
                                            } */}


                                        </div>




                                    </div>
                                </div>

                            </Col>
                            {/* <Col lg="3" className="nop">
                                {
                                    this.state.home && this.state.home.statistics ?
                                        <RightSidebar
                                            totalVehicles={this.state.home.statistics.totalVehicles}
                                            newMembers={this.state.home.statistics.newMembers}
                                            deliveryLines={this.state.home.statistics.deliveryLines}
                                            warehouses={this.state.home.statistics.warehouses}
                                            shops={this.state.home.statistics.shops}
                                            googlePlayLink={this.state.home.googlePlayLink}
                                            appStoreLink={this.state.home.appStoreLink}
                                            totalEmployess={this.state.home.statistics.totalEmployess}
                                        />
                                        :
                                        null
                                }
                            </Col> */}
                        </Row >
                    </Container >

                </section >

            </div >
        );
    }
}

export default Page(BlogPage);