import React, { Component } from 'react';
// import Link from '../components/link';
import { Link, Redirect } from 'react-router-dom';


import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import logo from '../assets/logo.svg';
import zajednica from '../assets/zajednica.svg';
import login from '../assets/images/login.png';
import shape1 from '../assets/svg/shape-1.svg';
import shape2 from '../assets/svg/shape-2.svg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';

import Form from '../components/forms/forgetPasswordForm';
import moment from 'moment';


var striptags = require('striptags');

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);

        this.state = {
            today: moment().format('dddd, DD.MM.YYYY.')
        };
    }

    componentDidMount() {

        // if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        // for (let i = 0; i < this.props.loadData.length; i++) {
        //     this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
        //         this.setState({
        //             ...data
        //         })
        //     })
        // }
        let day = this.state.today.split(",")[0];
        if (day == 'Monday') {
            day = "Ponedeljak";
            this.setState({ today: day + "," + this.state.today.split(",")[1] })
        } else if (day == 'Tuesday') {
            day = "Utorak";
            this.setState({ today: day + "," + this.state.today.split(",")[1] })
        } else if (day == 'Wednesday') {
            day = "Srijeda";
            this.setState({ today: day + "," + this.state.today.split(",")[1] })
        } else if (day == 'Thursday') {
            day = "Četvrtak";
            this.setState({ today: day + "," + this.state.today.split(",")[1] })
        } else if (day == 'Friday') {
            day = "Petak";
            this.setState({ today: day + "," + this.state.today.split(",")[1] })
        } else if (day == 'Saturday') {
            day = "Subota";
            this.setState({ today: day + "," + this.state.today.split(",")[1] })
        } else if (day == 'Sunday') {
            day = "Nedelja";
            this.setState({ today: day + "," + this.state.today.split(",")[1] })
        }

        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

    }



    submit(data) {
        
        fetch('https://interni-api.euroexpress.ba/forget-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        }).then((res) => res.json()).then((result) => {
            if (!result.error) {
                this.props[0].history.push('/');
            } else {
                this.setState({
                    error: result.error
                })
            }
        })
    }



    render() {

        return (
            <div className="test">

                <img src={shape1} className="shape-1" />
                <img src={shape2} className="shape-2" />

                <Container className="login-header">
                    <Row>
                        <Col lg="6">
                            <Isvg src={logo} />
                            <Isvg src={zajednica} />
                        </Col>
                        <Col lg="6" className="nav-right mr30">
                            <div className="date-container">
                                <span className="date-login">{this.state.today}</span>
                                <span className="time">{moment().format('HH:mm')}</span>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="login-page">

                    <Row className="login-page-container">
                        <div className="login-page-left">
                            <div className="login-box">
                                <h2>Dobrodošli</h2>
                                <p>Ukoliko ste zaboravali lozinku pošaljite nam Vaše korisničko ime i neko iz našeg tima će Vas kontakitrati.</p>
                                <img src={login} />
                            </div>
                        </div>
                        <div className="login-wrap login-page-right">
                            <div className="login-wrap-container">
                                <h2>Zaboravljena lozinka</h2>
                                <Form onSubmit={this.submit} />
                            </div>
                        </div>



                    </Row>
                </Container>

                <Container className="login-footer">
                    <Row>
                        <Col lg="6">
                            <span>© 2020 EuroExpress brza pošta.
                                <br></br>
                                All rights reserved.</span>
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }
}

export default Page(LoginPage);