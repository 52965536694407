import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'

import Text from './fields/text';
import Select from './fields/select';
import Textarea from './fields/textarea';
import arrows_right from '../../assets/arrows-right.png';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children,
    additionalAction,
    scope,
}) => (

    <Select
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        additionalAction={additionalAction}
        scope={scope}
        {...input}
        children={children}
    />
)

const renderTextField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type
}) => (

    <Text
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        {...input}
    />
)

const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

    <Textarea
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)


class form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }



    render() {

        const { handleSubmit, pristine, reset, submitting, menu } = this.props;
        // console.log(pristine, submitting);

        return (
            <form onSubmit={handleSubmit} className="sugestion-form none-box-shadow">
                <Row>
                    <Col lg="12">
                        <div className="field-wrap">
                            <Field
                                name="title"
                                component={renderTextField}
                                label={"Naslov".translate(this.props.lang)}
                                placeholder={"Unesite naslov Vaseg prijedloga".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>

                    <Col lg="12">
                        <div className="field-wrap">
                            {
                            menu ?
                            <Field
                                name="sector"
                                component={renderSelectField}
                                label={"Sektor"}
                                placeholder="Izaberite sektor za koji ostavljate prijedlog"
                            // validate={[required]}
                            >
                                {/* <option value="1">Opcija1</option>
                                <option value="2">Opcija2</option>
                                <option value="3">Opcija3</option>
                                <option value="4">Opcija4</option> */}

                                {
                                        menu && menu.map((item, idx) => {
                                            return (
                                                <option value={item._id}>{item.name}</option>
                                            )
                                        })
                                    }
                            </Field>
                            : null
                        }
                        </div>
                    </Col>


                    <Col lg="12">

                        <div className="field-wrap">
                            <Field
                                name="message"
                                component={renderTextareaField}
                                label={"Poruka".translate(this.props.lang)}
                                placeholder={"Unesite vas prijedlog...".translate(this.props.lang)}
                                validate={[required]}
                            ></Field>
                        </div>
                    </Col>

                    <Col lg="12" className="btn-wrap">
                        {this.props.loading ?
                            null
                            :
                            <button className="button">Posalji prijedlog <span><img src={arrows_right} alt="" /></span></button>
                        }

                    </Col>
                </Row>


            </form>

        )
    }
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
