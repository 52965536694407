import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown
} from 'reactstrap';


import moment from 'moment';

import { Player, Controls } from "@lottiefiles/react-lottie-player";

import no_content from '../assets/images/no_content.png';

import arrow_left from '../assets/arrow-left.svg';
import list_1 from '../assets/list-1.svg';
import list_2 from '../assets/list-2.svg';
import arrow_right from '../assets/arrow-right.svg';
import weather from '../assets/weather.svg';
import evidency from '../assets/evidency.png';
import sugestije from '../assets/sugestije.png';
import app from '../assets/app.png';
import b1 from '../assets/b1.png';
import shape1 from '../assets/svg/shape-1.svg';
import shape2 from '../assets/svg/shape-2.svg';
import noImage from '../assets/no-image.png';
import star from '../assets/star.svg';
import like from '../assets/like.svg';

import details_naslovna from '../assets/details-naslovna.jpg';

import ReactPaginate from 'react-paginate';
import RightSidebar from '../components/rightSidebar';
import { API_ENDPOINT } from '../constants';

import { Redirect } from 'react-router-dom';


var striptags = require('striptags');

class BlogPage extends Component {
    constructor(props) {
        super(props);
        this.updateStateFromSearch = this.updateStateFromSearch.bind(this);
        this.updateParam = this.updateParam.bind(this);
        this.get = this.get.bind(this);

        this.state = {
            promotedProjects: [],
            page: 0,
            view: 0,
            ...props.initialData
        };
    }

    updateStateFromSearch(callback) {
        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = { page: 0 };
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        this.setState({
            category: null,
            tag: null,
            items: []
        }, () => {
            this.setState(params, callback);

        })

    }


    updateParam(name, value) {

        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        params[name] = value;

        let paramsArr = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {

                paramsArr.push(key + "=" + params[key]);
            }
        }



        let search = '?' + paramsArr.join('&');


        this.props[0].history.push(this.props[0].location.pathname + search);
        ////console.log(search);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.updateStateFromSearch(() => {
                this.get()
            });
        }
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.get()
        }
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }


        fetch(`https://interni-api.euroexpress.ba/log/new`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                path: this.props[0].location.pathname,
            })
        }).then(res => res.json()).then((result) => {

        })




        this.updateStateFromSearch(this.get);


    }
    get() {



    }


    render() {
        return (
            <div>
                {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null
                }
                <img src={shape1} className="shape-1" />
                <img src={shape2} className="shape-2" />
                <section id="content">
                    <Container>
                        <Row>
                            <Col lg="9" className="nop">
                                <div className="content l-0 r-fix pd0">
                                    <div className="content-container">
                                        <div className="news-list-title">
                                            <div className="title-content">
                                                <h5 className="section-title">Novi član tima</h5>
                                                <div className="breadcumb">
                                                    <span>
                                                        <Link to="/">Početna</Link>&nbsp;/&nbsp;<a className="b-active">Novi član tima</a>
                                                    </span>


                                                </div>
                                            </div>
                                        </div>
                                        <div className="new-memeber-page-wrap">
                                            <div className="team-home home-member" onClick={() => {
                                                this.props[0].history.push('/novi-clan');
                                                // this.setState({ newMemberModal: this.state.home && this.state.home.newMember })
                                            }}>
                                                <img src={star} className="star" alt="svg" />
                                                <img src={like} className="like" alt="like" />
                                                {/* <h5 className="section-title">NOVI ČLAN TIMA</h5> */}
                                                {
                                                    this.state.home && this.state.home.newMember && this.state.home.newMember.image ?
                                                        <img src={this.state.home.newMember && API_ENDPOINT + this.state.home.newMember.image} alt="member" className="team-member" />
                                                        :
                                                        <img src={noImage} alt="member" className="team-member" />
                                                }


                                            </div>
                                            <div className="team-home-new-member-title">
                                                <h6>{this.state.home && this.state.home.newMember && this.state.home.newMember.title}</h6>
                                                <span>
                                                    <div dangerouslySetInnerHTML={{ __html: this.state.home && this.state.home.newMember && this.state.home.newMember.shortDescription }}></div>
                                                </span>
                                                <div style={{ marginTop: 20 }}>
                                            <div dangerouslySetInnerHTML={{ __html: this.state.home && this.state.home.newMember && this.state.home.newMember.description }}></div>

                                        </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                {/* <div className="content l-0 r-fix pd0">

                                    <Col lg="12">
                                        <div className="content-container">
                                            <article className="article-details">
                                                <h1>{this.state.home && this.state.home.newMember && this.state.home.newMember.title}</h1>

                                                <div dangerouslySetInnerHTML={{ __html: this.state.home && this.state.home.newMember && this.state.home.newMember.shortDescription }}></div>


                                                <div className="article-datails-image">
                                                    {
                                                        this.state.home && this.state.home.newMember && this.state.home.newMember.image ?
                                                            <img src={this.state.home.newMember && API_ENDPOINT + this.state.home.newMember.image} alt="member" className="team-member" />
                                                            :
                                                            <img src={noImage} alt="member" className="team-member" />
                                                    }
                                                </div>


                                                <div dangerouslySetInnerHTML={{ __html: this.state.home && this.state.home.newMember && this.state.home.newMember.description }}></div>




                                            </article>
                                        </div>
                                    </Col>
                                </div> */}
                            </Col>
                            <Col lg="3" className="nop">
                                {
                                    this.state.home && this.state.home.statistics ?
                                        <RightSidebar
                                            totalVehicles={this.state.home.statistics.totalVehicles}
                                            newMembers={this.state.home.statistics.newMembers}
                                            deliveryLines={this.state.home.statistics.deliveryLines}
                                            warehouses={this.state.home.statistics.warehouses}
                                            shops={this.state.home.statistics.shops}
                                            googlePlayLink={this.state.home.googlePlayLink}
                                            appStoreLink={this.state.home.appStoreLink}
                                            totalEmployess={this.state.home.statistics.totalEmployess}
                                        />
                                        :
                                        null
                                }
                            </Col>
                        </Row>
                    </Container>

                </section>

            </div>
        );
    }
}

export default Page(BlogPage);