import React, { Component } from 'react';
import { API_ENDPOINT } from '../constants';


class UploadVideoForPool extends Component {
    constructor(props) {
        super(props);

        this.state = {
            file: null
        };
    }

    selectFile(e) {
        let input = e.target;
        if (input.files && input.files[0]) {
            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))
            if (file > 524288) {
                this.setState({ error: "File size limit has been reached! (max 512 MB)" })
            } else {

                // this.setState({
                //     _loading: true
                // })

                let formData = new FormData();
                formData.append('file', input.files[0]);

                console.log('formData',formData)
                fetch(API_ENDPOINT + '/video/upload', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`

                    },
                    body: formData
                }).then((res) => res.text()).then((video) => {
                    console.log(video)
                    this.setState({
                        _loading: null
                    })
                });
            }


        }
    }




    render() {


        return (
            <div className='upload-video-for-pool-wrap'>
                <div className='upload-video-for-pool'>
                    <button className='upload-button' onClick={() => { }}>Upload video</button>
                    <input type="file" accept=".mp4, .avi, .webm, .mov" className='input-video' onChange={this.selectFile} />
                </div>

            </div>
        );
    }
}

export default UploadVideoForPool;