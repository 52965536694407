import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Header from '../components/header';
import Nav from '../components/nav';
import Footer from '../components/footer';


export const DefaultLayout = (Wrapped) => (props) => {
    console.log(props)
    return (
        <div>

            {
                props[0].location.pathname != '/prijava' && props[0].location.pathname != '/zaboravljena-lozinka' ?

                    <>
                        <Header {...props} />
                        <Nav {...props} />
                        <Wrapped {...props} />
                        <Footer {...props} />
                    </>
                    :
                    <>
                        <Wrapped {...props} />
                    </>

            }
            {/* <Header {...props} />
            <Nav {...props} />
            <Wrapped {...props} />
            <Footer {...props} /> */}
            {/*!props.cookies && props.lang == 'en' ? <div className="cookies">
                <p>We use cookies to personalize and optimize user experience. For more information, visit our <Link to='/privacy-policy' >Privacy Policy</Link>.</p>
                <button onClick={() => { props.allowCookies() }}>I AGREE</button>
            </div> :
                null
    */}

        </div>
    );
};

export default DefaultLayout;