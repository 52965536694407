import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'

import Text from './fields/text';
import Select from './fields/select';
import Date from './fields/date';
import Textarea from './fields/textarea';
import arrows_right from '../../assets/arrows-right.png';
import Isvg from 'react-inlinesvg';
import evidency2 from '../../assets/evidency2.svg';
// import arrows_right from '../../assets/arrows-right.png';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children,
    additionalAction,
    scope,
}) => (

    <Select
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        additionalAction={additionalAction}
        scope={scope}
        {...input}
        children={children}
    />
)
const renderDateField = ({
    input,
    placeholder,
    label,
    type,
    meta: { touched, error },
}) => (
    <Date
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        type={type}
        error={touched && error}
        {...input}
    />
)

const renderTextField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
    type
}) => (

    <Text
        placeholder={placeholder}
        errorText={touched && error}
        label={label}
        error={touched && error}
        type={type}
        {...input}
    />
)

const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

    <Textarea
        placeholder={placeholder}
        label={label}
        errorText={touched && error}
        error={touched && error}
        {...input}
    />
)


class form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }



    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props;
        // console.log(pristine, submitting);

        return (
            <form onSubmit={handleSubmit}>
                <div className="sugestion-form">
                    <Row>
                        <div className="calendar-form">
                            <Row>
                                <Col lg="4" className="calendar-row">
                                    <div className="calendar-left">
                                        <img src={evidency2} alt="" />
                                    </div>
                                </Col>
                                <Col lg="8">
                                    <div className="field-wrap">
                                        <Field
                                            name="date"
                                            component={renderDateField}
                                            validate={[required]}
                                        ></Field>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </div>

                <button className="button-classic">Prijavi odsustvo<span><img src={arrows_right} alt="" /></span></button>

            </form>

        )
    }
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
