import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import moment from "moment";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { srLatn, enGB } from 'date-fns/locale';


import { DateRange, DateRangePicker } from 'react-date-range';

import { useState } from 'react';


class DatePickerRange extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selection: {
                startDate: new Date(),
                endDate: new Date(),
            }


        };
    }





    handleSelect = (ranges) => {
        console.log(ranges);
        // console.log(ranges.range1.startDate);
        // console.log(ranges.range1.endDate);
        this.setState({
            selection: {
                startDate: ranges.range1.startDate,
                endDate: ranges.range1.endDate
            }
        })
        
        // console.log(this.state.selection)
    }


    render() {
        // const selectionRange = {
        //     startDate: new Date(),
        //     endDate: new Date(),
        //     key: 'selection',
        // }
        // const [state, setState] = useState([
        //     {
        //       startDate: new Date(),
        //       endDate: null,
        //       key: 'selection'
        //     }
        //   ]);
     
        const { selection } = this.state;

        return (
            <>

                <DateRange
                    editableDateInputs={true}
                    // onChange={item => setState([item.selection])}
                    onChange={this.props.onChange}
                    moveRangeOnFirstSelection={false}
                    ranges={this.props.value ? [this.props.value.range1] : [this.state.selection && this.state.selection]}
                    showDateDisplay={false}
                    disableDaysBeforeToday={true}
                    showMonthAndYearPickers={false}
                    locale={srLatn}
                    // selected={[this.props.value]}
                />
              
            </>


        );
    }
}

export default DatePickerRange;

