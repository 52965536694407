import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';


import {
    Container,
    Row,
    Col,
    Navbar,
    NavbarBrand,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Nav, NavItem,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption

} from 'reactstrap';

import facebook_icon from '../assets/svg/facebook-icon.svg'
import instagram_icon from '../assets/svg/instagram-icon.svg'
import phone_icon from '../assets/svg/header-phone.svg'
import mail_icon from '../assets/svg/header-mail.svg'

import hamburger_icon from '../assets/svg/hamburger-icon.svg'

import logo from '../assets/logo.svg';
import noImage from '../assets/no-image.jpg';
import zajednica from '../assets/zajednica.svg';
import arrow_black from '../assets/arrow-black.svg';
import profile from '../assets/profile.png';
import moment from 'moment';
import { API_ENDPOINT } from '../../../admin/src/constants';





class HomeHeader extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.suggestions = this.suggestions.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.state = {
            suggestions: [],
            search: '',
            imageErrors: {},
            width: 0, height: 0,
            showSearch: false,
            today: moment().format('dddd, DD.MM.YYYY.')
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);


    }





    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }
        document.addEventListener('mousedown', this.handleClickOutside);
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);


        let day = this.state.today.split(",")[0];
        if (day == 'Monday') {
            day = "Ponedeljak";
            this.setState({ today: day + "," + this.state.today.split(",")[1] })
        } else if (day == 'Tuesday') {
            day = "Utorak";
            this.setState({ today: day + "," + this.state.today.split(",")[1] })
        } else if (day == 'Wednesday') {
            day = "Srijeda";
            this.setState({ today: day + "," + this.state.today.split(",")[1] })
        } else if (day == 'Thursday') {
            day = "Četvrtak";
            this.setState({ today: day + "," + this.state.today.split(",")[1] })
        } else if (day == 'Friday') {
            day = "Petak";
            this.setState({ today: day + "," + this.state.today.split(",")[1] })
        } else if (day == 'Saturday') {
            day = "Subota";
            this.setState({ today: day + "," + this.state.today.split(",")[1] })
        } else if (day == 'Sunday') {
            day = "Nedelja";
            this.setState({ today: day + "," + this.state.today.split(",")[1] })
        }


        // fetch('https://interni-api.euroexpress.ba/user', {
        //     method: 'GET',
        //     headers: {
        //         'content-type': 'application/json',
        //         'Authorization': `Bearer ${localStorage.getItem('token')}`
        //     }
        // }).then(res => res.json()).then((result) => {
        //     console.log(result);
        //     this.setState({
        //         user: result
        //     })
        // })

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }


    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });

        this.props.socketIOClient.on('suggestions', (data) => {

            this.setState({
                imageErrors: {},
                suggestions: data.products
            })
        });



    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        window.removeEventListener('resize', this.updateWindowDimensions);

        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("suggestions");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }


    suggestions() {
        if (this.state.search.length) {
            this.props.socketIOClient.emit("suggestions", { search: this.state.search });
        }
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                suggestions: []
            })
        }

        if (this.leftMenuRef && !this.leftMenuRef.contains(event.target)) {
            this.setState({
                leftMenu: null
            })
        }

        /*if (this.rightMenuRef && !this.rightMenuRef.contains(event.target)) {
            this.setState({
                rightMenu: null
            })
        }*/
    }
    logout = () => {

        fetch(`https://interni-api.euroexpress.ba/log/new`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                path: '/logout',
            })
        }).then(res => res.json()).then((result) => {
            localStorage.removeItem('token');
            window.location.reload(false);
        })



    }


    render() {
        return (
            <div>
                <Container>
                    <Row>
                        <Col lg="12">
                            <header>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6 col-xs-6 v-center">
                                            <div className="nav-left">
                                                <Link to="/" className="logo">
                                                    <Isvg src={logo} />
                                                </Link>
                                                <Isvg src={zajednica} />
                                                {/* <div className="web-site">
                                                    <a href="https://euroexpress.ba/" target="_blank">Internet<br/>stranica</a>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xs-6 d-flex flex-end">
                                            <div className="nav-right">
                                                <div className="date-container">
                                                    {/* <span className="date">Petak, 28.06.2020</span> */}
                                                    <span className="date">{this.state.today}</span>
                                                    <span className="time">{moment().format('HH:mm')}</span>
                                                </div>
                                                <div className="profile">
                                                    <div className="profile-name">
                                                        {
                                                            this.props.user && this.props.user.username ?

                                                                <UncontrolledDropdown>
                                                                    <DropdownToggle className="menu-link">
                                                                        <h6>{this.props.user.firstName} {this.props.user.lastName}</h6>
                                                                        <span className="dropdown-icon">
                                                                            <img src={arrow_black} alt="" />
                                                                        </span>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu className="w150">
                                                                        <Link to="/podesavanja"><DropdownItem> Podešavanja</DropdownItem></Link>
                                                                        <a><DropdownItem onClick={this.logout}> Odjavi se</DropdownItem></a>

                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                                :
                                                                <Link to="/prijava"> <h6>Prijava </h6></Link>
                                                        }

                                                    </div>
                                                    {
                                                        this.props.user && this.props.user.username ?
                                                            <div className="profile-image">
                                                                {
                                                                    this.props.user.image ?
                                                                        <img src={API_ENDPOINT + this.props.user.image} />
                                                                        :
                                                                        <img src={noImage} />
                                                                }

                                                            </div>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>

                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}


export default HomeHeader;
